import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const BnPl = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-6xl font-bold text-gray-900 mb-6">
              Buy Now - Pay Later!
            </h1>
            <h2 className="text-2xl font-bold text-purple-600 mb-4">
              Shop Now, Pay Later Without Extra Charges!
            </h2>
            <p className="text-xl text-gray-700">
              Smart Payments. Zero Interest. Total Convenience.
            </p>
          </div>

          {/* Main Description */}
          <div className="space-y-8">
            <div className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-xl shadow-sm">
              <p className="text-gray-700 leading-relaxed text-justify">
                At CleverPe, we believe that affordability should never be a barrier to getting what you need. With our No-Cost EMI, you can buy now and pay later in easy installments—without any extra cost. Whether it's your dream gadget, home essentials, or even an electric vehicle, we make payments effortless and stress-free.
              </p>
            </div>

            {/* Benefits Section */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Why Choose CleverPe's No-Cost EMI?
              </h2>
              <div className="grid md:grid-cols-3 gap-6">
                {[
                  {
                    title: "Zero Interest, Zero Worries",
                    desc: "Unlike traditional EMIs that charge interest, CleverPe No-Cost EMI ensures you only pay for what you buy—no hidden fees, no surprises."
                  },
                  {
                    title: "⚡ Instant Approval in 2 Minutes",
                    desc: "Get real-time credit approval with just your Name & Mobile Number—no complex documentation, no waiting!"
                  },
                  {
                    title: "No Credit Card Required",
                    desc: "You don't need a credit card or prior credit history. Just choose CleverPe No-Cost EMI at checkout and get started instantly."
                  },
                  {
                    title: "Flexible Repayment Plans",
                    desc: "Enjoy customized tenure options that suit your budget, making it easier to manage payments on your terms."
                  },
                  {
                    title: "Available Online & In-Store",
                    desc: "Whether you're shopping at your favorite retail store or online, CleverPe No-Cost EMI is there for you."
                  }
                ].map((item, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-3 text-gray-900">
                      {item.title}
                    </h3>
                    <p className="text-gray-700">{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* How It Works Section */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                How Does It Work?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {[
                  {
                    step: "1",
                    title: "Select CleverPe No-Cost EMI",
                    desc: "At participating merchants, choose CleverPe No-Cost EMI as your payment option."
                  },
                  {
                    step: "2",
                    title: "Quick & Easy Approval",
                    desc: "Enter your Name & Mobile Number—get instant approval with no income proof or credit history required."
                  },
                  {
                    step: "3",
                    title: "Choose Your EMI Plan",
                    desc: "Pick a flexible repayment tenure that works best for you."
                  },
                  {
                    step: "4",
                    title: "Own It Today, Pay Later",
                    desc: "Enjoy your purchase immediately while making easy, interest-free payments over time."
                  }
                ].map((item) => (
                  <div key={item.step} className="flex flex-col items-center p-6 bg-purple-50 rounded-lg">
                    <span className="w-10 h-10 bg-purple-600 text-white rounded-full flex items-center justify-center mb-4">
                      {item.step}
                    </span>
                    <h3 className="text-lg font-semibold mb-2 text-center">{item.title}</h3>
                    <p className="text-center text-gray-600">{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Eligibility Section */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Who Can Apply?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  "Any Indian citizen (18 years to 55 years)",
                  "Salaried & self-employed individuals",
                  "No prior credit history needed",
                  "Just a mobile number—no lengthy forms or KYC scans"
                ].map((item, index) => (
                  <div key={index} className="bg-purple-50 p-6 rounded-lg">
                    <div className="flex items-center">
                      <div className="w-2 h-2 bg-purple-600 rounded-full mr-3"></div>
                      <span className="text-gray-800">{item}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Products Section */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                What Can You Buy With No-Cost EMI?
              </h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {[
                  "Smartphones & Gadgets",
                  "Home Appliances",
                  "Fashion & Lifestyle Products",
                  "Furniture & Home Décor",
                  "Electric Vehicles",
                  "Travel & Experiences",
                  "And much more!"
                ].map((item) => (
                  <div key={item} className="bg-purple-50 p-4 rounded-lg text-center">
                    <span className="text-gray-800 font-medium">{item}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* CTA Section */}
            <div className="mt-12 text-center py-8 bg-gradient-to-r from-purple-50 to-white rounded-xl">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Experience Smart Shopping with CleverPe No-Cost EMI!
              </h2>
              <p className="text-gray-700 mb-6">
                Why pay in full when you can split payments at zero extra cost?
              </p>
              <a
                href="/merchantlocater"
                className="inline-block px-8 py-4 bg-gradient-purple text-white font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5] transform hover:scale-105 transition-transform"
              >
                Find a Merchant Near You
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default BnPl;