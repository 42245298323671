import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import CleverPeLogo from "@/assets/logo/CleverPe_White_H.svg";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="relative z-50 py-6">
      <nav className="max-w-[1440px] mx-auto px-8">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
            <img
              src={CleverPeLogo}
              alt="CleverPe"
              className="h-[32px] w-auto"
            />
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white text-2xl focus:outline-none"
            >
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Navigation Links */}
          <div
            className={`fixed inset-0 flex flex-col items-center justify-center space-y-6 bg-gray-900 bg-opacity-90 transition-transform duration-300 ${
              isMenuOpen ? "translate-x-0" : "translate-x-full"
            } md:static md:flex md:flex-row md:space-y-0 md:space-x-14 md:translate-x-0 md:bg-transparent  md:mx-auto`}
          >
            {/* Close Button Icon */}
            {isMenuOpen && (
              <button
                onClick={closeMenu}
                className="absolute top-4 right-4 text-white text-3xl md:hidden"
              >
                <FaTimes />
              </button>
            )}

            <a
              href="/"
              onClick={closeMenu}
              className="text-[16px] text-white/70 hover:text-white transition-colors duration-200"
            >
              Home
            </a>
            <a
              href="#featurescard"
              onClick={closeMenu}
              className="text-[16px] text-white/70 hover:text-white transition-colors duration-200"
            >
              Solution
            </a>
            <a
              href="/marketplace"
              onClick={closeMenu}
              className="text-[16px] text-white/70 hover:text-white transition-colors duration-200"
            >
              Marketplace
            </a>
            <a
              href="#evfinancing"
              onClick={closeMenu}
              className="text-[16px] text-white/70 hover:text-white transition-colors duration-200"
            >
              EV Financing
            </a>
            <a
              href="/aboutus"
              onClick={closeMenu}
              className="text-[16px] text-white/70 hover:text-white transition-colors duration-200"
            >
              About
            </a>

            {/* Mobile Sign In Button */}
            {/* <button
              onClick={closeMenu}
              className="md:hidden flex items-center justify-center gap-2 px-4 py-2 rounded-[12px] border border-[#DCDCDC] bg-white/10 hover:bg-white/20 transition duration-300"
              style={{
                width: "130px",
                height: "48px",
                fontFamily: "Gilroy",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
            <a href="/marketplace"
            className="text-white no-underline"
            >
              Sign In
              </a> 
            </button> */}
          </div>

          {/* Desktop Sign In Button */}
          {/* <button
            className="hidden md:flex items-center justify-center gap-2 px-4 py-2 rounded-[12px] border border-[#DCDCDC] bg-white/10 hover:bg-white/20 transition duration-300"
            style={{
              width: "130px",
              height: "48px",
              fontFamily: "Gilroy",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            <a href="/marketplace"
            className="text-white no-underline"
            >
            Sign In
            </a>
          </button> */}
        </div>
      </nav>
    </header>
  );
}
