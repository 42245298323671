import React, { useState } from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const ContactUs = () => {
  const [isMapLoading, setIsMapLoading] = useState(true);

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-xl mx-auto">
          <h1 className="text-6xl font-bold text-center text-gray-900 mb-12">
            Contact Us
          </h1>

          {/* Added border to the main container */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 border-2 border-[#5865F2] rounded-lg p-8">
            {/* Contact Form */}
            <div className="bg-white rounded-lg mt-4">
              <form className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    className="w-3/4 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    className="w-3/4 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    placeholder="Enter your phone number"
                    className="w-3/4 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Reason
                  </label>
                  <textarea
                  placeholder="Enter the reason for contacting us"
                  className="w-3/4 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  style={{ resize: 'none' }}
                  />
                </div>

                <button
                  type="submit"
                  className="w-32 px-6 py-3 bg-gradient-purple text-white font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5] flex items-center justify-between"
                >
                  Submit
                  <span className="ml-2">→</span>
                </button>
              </form>

              {/* Contact Information */}
              <div className="mt-8 space-y-4">
                <div className="flex items-center text-gray-600">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <a href="mailto:support@cleverpe.com" className="text-gray-600">support@cleverpe.com</a>
                </div>
                <div className="flex items-center text-gray-600">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span className="text-gray-600">WeWork - RMZ Latitude -10th floor, RMZ Latitude Commercial Building,<br/> Bellary Rd, Hebbal, Bengaluru, Karnataka 560024</span>
                </div>
              </div>
            </div>

            {/* Map with Skeleton */}
            <div className="h-[500px] rounded-lg overflow-hidden relative">
              {isMapLoading && (
                <div className="absolute inset-0 bg-gray-200 animate-pulse flex items-center justify-center">
                  <div className="space-y-4 w-full p-8">
                    <div className="h-4 bg-gray-300 rounded w-3/4 mx-auto"></div>
                    <div className="h-4 bg-gray-300 rounded w-1/2 mx-auto"></div>
                    <div className="h-32 bg-gray-300 rounded w-5/6 mx-auto mt-8"></div>
                    <div className="flex justify-center space-x-2 mt-8">
                      <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                      <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                      <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>
                </div>
              )}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243.03756974588828!2d77.62023819871609!3d12.920850637917774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14f79eb37ba3%3A0x8eabbc4209024aba!2sCleverpe%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1706624320927!5m2!1sen!2sin"
                height="100%"
                width="100%"
                style={{ border: 0 }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                onLoad={() => setIsMapLoading(false)}
              />
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default ContactUs;