import React from 'react';

interface ResultStepProps {
  creditLineStatus: 'success' | 'failure' | null;
  customerName: string;
  amount: number | null;
  handleShare: () => void;
}

const ResultStep: React.FC<ResultStepProps> = ({
  creditLineStatus,
  customerName,
  amount,
  handleShare
}) => {
  return (
    <>
      {creditLineStatus !== null && (
        <>
          {creditLineStatus === 'success' && (
            <div className="w-full h-[400px] md:h-[261px] bg-radial-green-glow rounded-[20px] text-white p-10 relative overflow-hidden shadow-lg">
              {/* Desktop View */}
              <div className="hidden md:block">
                <div className="absolute text-gradient-light left-[33px] top-[71px] text-[38px] font-bold capitalize leading-[57.60px]">
                  Congratulations
                </div>
                <div className="absolute left-[33px] top-[127px] text-[35px] font-medium leading-[43.70px]">
                  {customerName}
                </div>
                <div 
                  className="absolute left-[33px] bottom-10 text-[16px] underline font-medium cursor-pointer"
                  onClick={handleShare}
                >
                  Invite others to check
                </div>
              </div>

              {/* Mobile View */}
              <div className="md:hidden text-center">
                <div className="text-gradient-light text-[38px] font-bold capitalize leading-[57.60px] mb-4">
                  Congratulations
                </div>
                <div className="text-[35px] font-medium leading-[43.70px] mb-4">
                  {customerName}
                </div>
                <div className="w-[240px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent mx-auto my-4"></div>
                <div className="text-white text-[23px] font-medium leading-relaxed mb-4">
                  credit line of
                </div>
                <div className="flex justify-center items-center mb-4">
                  <div className="text-white text-[40px] font-normal leading-[54px]">₹</div>
                  <div className="text-gradient-light text-[75px] font-bold capitalize leading-[90px] ml-2">
                    {amount}
                  </div>
                </div>
                <div className="text-white text-[23px] font-medium leading-relaxed mb-4">
                  is Approved
                </div>
                <div 
                  className="absolute bottom-10 left-[50%] transform -translate-x-[50%] text-[16px] underline font-medium cursor-pointer"
                  onClick={handleShare}
                >
                  Invite others to check
                </div>
              </div>
            </div>
          )}
          
          {creditLineStatus === 'failure' && (
            <div className="w-full h-[400px] md:h-[261px] bg-radial-red-glow rounded-[10px] text-white p-10 relative overflow-hidden shadow-[1px]">
              {/* Desktop View */}
              <div className="hidden md:block">
                <div className="absolute text-gradient-light left-[10px] top-[40px] text-[30px] font-bold capitalize leading-[40px] w-full whitespace-nowrap">
                  Credit Line Not Approved
                </div>
                <div className="absolute left-[10px] top-[90px] text-[20px] font-medium">
                  We couldn't approve your credit line at this moment, but there's still more to explore!
                </div>
                <div 
                  className="absolute left-[33px] bottom-10 text-[16px] underline font-medium cursor-pointer"
                  onClick={handleShare}
                >
                  Invite others to check
                </div>
              </div>

              {/* Mobile View */}
              <div className="md:hidden text-center">
                <div className="text-gradient-light text-[30px] font-bold capitalize leading-[40px] mb-4">
                  Credit Line Not Approved
                </div>
                <div className="w-[240px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent mx-auto my-4"></div>
                <div className="text-white text-[20px] font-medium mb-12 mt-8">
                  We couldn't approve your credit line at this moment, but there's still more to explore!
                </div>
                <div 
                  className="absolute bottom-10 left-[50%] transform -translate-x-[50%] text-[16px] underline font-medium cursor-pointer"
                  onClick={handleShare}
                >
                  Invite others to check
                </div>
              </div>
            </div>
          )}
        </>
      )}
      
      {creditLineStatus === 'success' && (
        <div className="sm:w-1/2 w-full sm:pl-12 pt-4 flex flex-col justify-center">
          <div className="relative w-full hidden md:block">
            <div className="w-[210px] left-[50%] transform -translate-x-[50%] top-[-100px] absolute text-white text-[23px] font-medium leading-relaxed">
              credit line of
            </div>
            <div className="w-full flex justify-center items-center top-[-70px] absolute">
              <div className="text-white text-[40px] font-normal leading-[54px]">₹</div>
              <div className="text-gradient-light text-[75px] font-bold capitalize leading-[90px] ml-2">
                {amount}
              </div>
            </div>
            <div className="w-[210px] left-[50%] transform -translate-x-[50%] top-[15px] absolute text-white text-[23px] font-medium leading-relaxed">
              is Approved
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResultStep;
