import React from 'react';

// Define types
type Logo = string;

const Partners = () => {
  const MerchantLogos: Logo[] = [
    new URL('@/assets/partners/Bnew.png', import.meta.url).href,
    new URL('@/assets/partners/Global.png', import.meta.url).href,
    new URL('@/assets/partners/Lotus.png', import.meta.url).href,
    new URL('@/assets/partners/LuvMobiles.png', import.meta.url).href,
    new URL('@/assets/partners/ModStore.svg', import.meta.url).href,
    new URL('@/assets/partners/phonerange.png', import.meta.url).href,
    new URL('@/assets/partners/Poojara.svg', import.meta.url).href,
    new URL('@/assets/partners/Sangheeta.svg', import.meta.url).href,
    new URL('@/assets/partners/SeemaE.png', import.meta.url).href,
    new URL('@/assets/partners/Slv.png', import.meta.url).href,
    new URL('@/assets/partners/SmartDukaan.png', import.meta.url).href,
    new URL('@/assets/partners/venky.png', import.meta.url).href,
    new URL('@/assets/partners/whitemart.png', import.meta.url).href,
    new URL('@/assets/partners/Ddd.png', import.meta.url).href,
    new URL('@/assets/partners/hotspot.png', import.meta.url).href,

  ];

  const BrandLogos: Logo[] = [
    new URL('@/assets/partners/Benco.svg', import.meta.url).href,
    new URL('@/assets/partners/Symphony.svg', import.meta.url).href,
    new URL('@/assets/partners/Conekt.png', import.meta.url).href,
  ];

  const createTripleLogos = (logos: Logo[]): Logo[] => {
    return [...logos, ...logos, ...logos];
  };

  return (
    <>
      <style>
        {`
          @keyframes scrollLeft {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          @keyframes scrollRight {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(0);
            }
          }

          .partners-wrapper {
            margin: 0 auto;
            position: relative;
            width: 100%;
            overflow: hidden;
            background: transparent;
            padding: 2rem;
          }

          .scroll-container {
            overflow: hidden;
            position: relative;
            width: 100%;
            padding: 1rem 0;
          }

          .brick-container {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            width: 100%;
          }

          .brick-row {
            display: flex;
            gap: 15px;
            animation-duration: 20s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }

          .brick-row.scroll-left {
            animation-name: scrollLeft;
          }

          .brick-row.scroll-right {
            animation-name: scrollRight;
          }

          .brick-row:hover {
            animation-play-state: paused;
          }

          .side-gradient {
            position: absolute;
            top: 0;
            width: 90px;
            height: 100%;
            z-index: 20;
            background:  linear-gradient(270deg, #1D1D1D -39.39%, #838383 1015.15%);
            filter: blur(26px);
            pointer-events: none;
            border-radius: 0 100% 100% 0;
          }

          @media screen and (max-width: 768px) {
            .side-gradient {
              display: none;
            }
          }

          .left-gradient {
            left: 0;
            transform: rotate(180deg);
          }

          .right-gradient {
            right: 0;
          }

          .rectangle {
            width: 200px;
            height: 100px;
            border-radius: 16px;
            border: 2px solid rgba(255, 255, 255, 0.3);
            background: rgba(30, 30, 30, 0.7);
            backdrop-filter: blur(8px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            padding: 1rem;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s ease, box-shadow 0.2s ease;
          }

          .rectangle:hover {
            transform: translateY(-2px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
          }

          .logo-image {
            max-width: 90%;
            max-height: 90%;
            object-fit: contain;
            transition: transform 0.2s ease;
          }

          .rectangle:hover .logo-image {
            transform: scale(1.1);
          }

          /* Responsive styles */
          @media screen and (max-width: 768px) {
            .rectangle {
              width: 170px;
              height: 85px;
            }
            .brick-row {
              animation-duration: 20s;
              width: 400%;
            }
          }
          }
        `}
      </style>

      <section className="relative z-10 mb-16 text-center">
        <h2 className="text-[44px] font-bold tracking-normal text-gradient-light mb-4">Our Trusted Partners</h2>
        <p className="mt-4 text-[20px] leading-[24px] font-medium, max-w-[900px] mx-auto text-gradient-light">
          We collaborate with leading industry partners to bring you innovative solutions. Together, we strive to make financial services more accessible and efficient for everyone.
        </p>
        
        <div className="partners-wrapper">
        <div className="side-gradient left-gradient"></div>
        <div className="side-gradient right-gradient"></div>
          {/* Brands Section */}
          <div className="text-[36px] font-bold tracking-normal text-gradient-light mb-4">Brands</div>
          <div className="scroll-container">
            <div className="brick-row scroll-left">
              {createTripleLogos(BrandLogos).map((logo, index) => (
                <div key={`brand-${index}`} className="rectangle">
                  <img
                    src={logo}
                    alt={`Brand Partner ${index + 1}`}
                    className="logo-image"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Merchants Section */}
          <div className="text-[36px] font-bold tracking-normal text-gradient-light mb-4">Merchants</div>
          <div className="scroll-container">
            <div className="brick-container">
              {[MerchantLogos.slice(0, Math.ceil(MerchantLogos.length / 2)), MerchantLogos.slice(Math.ceil(MerchantLogos.length / 2))].map((rowLogos, rowIndex) => (
                <div 
                  key={`merchant-row-${rowIndex}`} 
                  className={`brick-row ${rowIndex % 2 === 0 ? 'scroll-left' : 'scroll-right'}`}
                >
                  {createTripleLogos(rowLogos).map((logo, index) => (
                    <div key={`merchant-${rowIndex}-${index}`} className="rectangle">
                      <img
                        src={logo}
                        alt={`Merchant Partner ${index + 1}`}
                        className="logo-image"
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Partners;