import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const EmiGuide = () => {
  const handleRedirect = () => {
    window.location.href = "/#credit-limit-section";
  };
    
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          <h1 className="text-6xl font-bold text-center text-gray-900 mb-12">
            EMI Guides
          </h1>

          <h2 className="text-xl font-bold text-gray-900 mb-2">
            EMI Guide: Understanding Equated Monthly Installments (EMIs) with CleverPe
          </h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6">
            CleverPe makes financing easy and hassle-free with our simple, transparent, and flexible EMI options. Here’s everything you need to know about how EMIs work and how you can use them to your advantage.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">1. What is an EMI?</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6">
            An Equated Monthly Installment (EMI) is a fixed amount you pay every month to repay your loan or purchase amount. EMIs are designed to break down large payments into smaller, manageable sums over a specified tenure.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">2. Benefits of EMIs with CleverPe</h2>
          <ul className="list-disc pl-6 text-gray-700 mb-6">
            <li><strong>No Cost EMIs:</strong> Enjoy 0% interest rates on select purchases. Pay only the actual price of the product over the chosen tenure.</li>
            <li><strong>Flexible Tenure Options:</strong> Choose a repayment plan that works for you—spanning from 3 months to 24 months.</li>
            <li><strong>Cardless Financing:</strong> No credit card? No problem! Use CleverPe to avail of EMIs with minimal documentation.</li>
            <li><strong>100% Digital Process:</strong> Apply, verify, and start your EMI journey within minutes—all online and paperless.</li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">3. How to Avail EMIs with CleverPe</h2>
          <ul className="list-decimal pl-6 text-gray-700 mb-6">
            <li><strong>Check Eligibility:</strong> Enter your Name (as per PAN) and Mobile Number on our “Check Credit Limit” form. Get instant approval and your credit limit details.</li>
            <li><strong>Select an Option:</strong> Shop with partner merchants (online or offline). Opt for financing during checkout.</li>
            <li><strong>Choose a Plan:</strong> Select your preferred EMI tenure. Confirm your purchase and repayment schedule.</li>
            <li><strong>Enjoy Your Purchase:</strong> Start using your product or service immediately while paying in easy installments.</li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">4. Example EMI Calculation</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6">
            <strong>Purchase Amount:</strong> ₹12,000
            <br />
            <strong>Tenure:</strong> 6 Months
            <br />
            <strong>No Cost EMI:</strong> ₹2,000/month
            <br />
            For regular EMI options, interest rates may vary based on your credit profile and tenure. Use the EMI calculator on our platform to get exact details.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">5. Documents Required</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6">
            CleverPe simplifies the process with minimal documentation:
            <ul className="list-disc pl-6 text-gray-700">
              <li>PAN card details</li>
              <li>Registered mobile number</li>
              <li>No salary statement or extensive paperwork is needed!</li>
            </ul>
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">6. Frequently Asked Questions (FAQs)</h2>
          <ul className="pl-6 text-gray-700 mb-6">
            <li><strong>Q: Can I prepay my EMIs?</strong> A: Yes, CleverPe allows early repayment without penalties on select plans.</li>
            <li><strong>Q: What happens if I miss an EMI?</strong> A: Missing an EMI may incur late fees or impact your credit limit. Ensure timely payments to maintain your credit score.</li>
            <li><strong>Q: Can I use EMIs for any purchase?</strong> A: EMIs are available for eligible transactions made at partner merchants. Check our marketplace for participating stores.</li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">7. Ready to Get Started?</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6">
            Say goodbye to financial stress and hello to affordability. CleverPe makes owning what you love easier than ever!
          </p>

          {/* CTA Button */}
          <button
            onClick={handleRedirect}
            className="inline-block px-8 py-4 bg-gradient-purple text-white font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
          📥 Check Your Credit Limit
          </button>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default EmiGuide;