import React from 'react';

type EVLogo = {
    src: string;
    text: string;
};

const EVPartners = () => {
    const evPartnersLogos: EVLogo[] = [
        { src: new URL('@/assets/evpartners/JoyBike.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Baguass.svg', import.meta.url).href, text: 'BGauss' },
        { src: new URL('@/assets/evpartners/GreenTiger.svg', import.meta.url).href, text: 'Green Tiger' },
        { src: new URL('@/assets/evpartners/Starya.svg', import.meta.url).href, text: 'Starya' },
        { src: new URL('@/assets/evpartners/Gemopai.svg', import.meta.url).href, text: 'Gemopai' },
        { src: new URL('@/assets/evpartners/SesElectric.svg', import.meta.url).href, text: 'SES Electric' },
        { src: new URL('@/assets/evpartners/Runr.png', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Vegh.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Warivo.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Esprinto.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Bmr.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Battire.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Enigma.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/EcoTejas.svg', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/ElectricPe.png', import.meta.url).href, text: '' },
        { src: new URL('@/assets/evpartners/Starya.png', import.meta.url).href, text: '' },

    ];

    const createTripleLogos = (logos: EVLogo[]): EVLogo[] => {
        return [...logos, ...logos];
    };

    const rows = [
        evPartnersLogos.slice(0, 5),
        evPartnersLogos.slice(5, 10),
        evPartnersLogos.slice(10, 16)
    ];

    return (
        <>
            <style>
                {`
                    @keyframes evScrollLeft {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(-33.33%);
                        }
                    }

                    @keyframes evScrollRight {
                        0% {
                            transform: translateX(-33.33%);
                        }
                        100% {
                            transform: translateX(0);
                        }
                    }

                    .ev-partners-wrapper {
                        margin: 0 auto;
                        position: relative;
                        width: 100%;
                        overflow: hidden;
                        background: transparent;
                        padding: 2rem;
                    }

                    .ev-side-gradient {
                        position: absolute;
                        top: 0;
                        width: 90px;
                        height: 100%;
                        z-index: 20;
                        background: linear-gradient(270deg, rgba(0, 255, 163, 0.05) -45.39%, rgba(0, 255, 163, 0.2) 915.15%);
                        filter: blur(26px);
                        pointer-events: none;
                        border-radius: 0 100% 100% 0;
                    }

                    .ev-left-gradient {
                        left: 0;
                        transform: rotate(180deg);
                    }

                    .ev-right-gradient {
                        right: 0;
                    }

                    .ev-scroll-container {
                        overflow: hidden;
                        position: relative;
                        width: 100%;
                        padding: 1rem 0;
                    }

                    .ev-brick-container {
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;
                        width: 100%;
                    }

                    .ev-brick-row {
                        display: flex;
                        gap: 15px;
                        animation-duration: 10s;
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;
                        width: 100%;
                    }

                    .ev-brick-row.ev-scroll-left {
                        animation-name: evScrollLeft;
                    }

                    .ev-brick-row.ev-scroll-right {
                        animation-name: evScrollRight;
                        transform: translateX(-33.33%);
                    }

                    .ev-brick-row:hover {
                        animation-play-state: paused;
                    }

                    .ev-rectangle {
                        width: 200px;
                        height: 100px;
                        border-radius: 16px;
                        border: 2px solid rgba(10, 13, 23, 0.1);
                        background: rgba(255, 255, 255, 0.9);
                        backdrop-filter: blur(8px);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;
                        padding: 1.5rem;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        transition: transform 0.2s ease, box-shadow 0.2s ease;
                    }

                    .ev-rectangle:hover {
                        transform: translateY(-2px);
                        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
                    }

                    .ev-logo-image {
                        max-width: 90%;
                        max-height: 95%;
                        object-fit: contain;
                        transition: transform 0.2s ease;
                    }

                    .ev-rectangle:hover .ev-logo-image {
                        transform: scale(1.1);
                    }

                    .ev-logo-text {
                        margin-top: 0.5rem;
                        font-size: 1rem;
                        font-weight: 500;
                        color: #4B5563;
                    }

                    @media (max-width: 768px) {
                        .ev-partners-wrapper {
                            padding: 1rem;
                        }

                        .ev-brick-container {
                            gap: 1rem;
                        }

                        .ev-brick-row {
                            gap: 10px;
                        }

                        .ev-rectangle {
                            width: 170px;
                            height: 85px;
                            padding: 1.3rem;
                        }

                        section p {
                            white-space: normal;
                            padding: 0 0.5rem;
                         
                        }
                        .ev-brick-row {
                            animation-duration: 10s;
                            width: 400%;
                        }
                      }

                `}
            </style>

            <section className="relative z-10 mb-2 lg:mb-16 text-center">
                <h2 className="text-5xl font-bold bg-gradient-green bg-clip-text text-transparent mb-6 mt-16">Our Trusted EV Partners</h2>
                <p className="mt-4 text-sm lg:text-xl leading-normal font-medium max-w-4xl mx-auto bg-gradient-green bg-clip-text text-transparent">
                    Join the eco-friendly revolution with CleverPe. Get loans of up to ₹2,00,000 for your dream electric bike in just 30 minutes. Enjoy a 100% digital and paperless process.
                </p>

                <div className="ev-partners-wrapper">
                    <div className="ev-side-gradient ev-left-gradient"></div>
                    <div className="ev-side-gradient ev-right-gradient"></div>
                    <div className="ev-scroll-container">
                        <div className="ev-brick-container">
                            {rows.map((rowLogos, rowIndex) => (
                                <div
                                    key={`ev-row-${rowIndex}`}
                                    className={`ev-brick-row ${rowIndex === 1 ? 'ev-scroll-right' : 'ev-scroll-left'}`}
                                >
                                    {createTripleLogos(rowLogos).map((logo, index) => (
                                        <div key={`ev-logo-${rowIndex}-${index}`} className="ev-rectangle">
                                            <img
                                                src={logo.src}
                                                alt={`EV Partner ${index + 1}`}
                                                className="ev-logo-image"
                                            />
                                            {logo.text && (
                                                <span className="ev-logo-text">{logo.text}</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EVPartners;