import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";
import { ArrowRight, Store, Phone } from "lucide-react"; 

const PartnerWithCleverPe = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12 ">
        <div className="max-w-screen-lg mx-auto">
          <h1 className="text-6xl font-bold text-center text-gray-900 mb-12">
            Partner with Us
          </h1>

          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            Partner with CleverPe and Redefine How Your Business Engages with Customers, whether you’re a merchant, multi-chain brand, OEM, or EV dealer, our solutions are designed to boost your sales, enhance customer satisfaction, and simplify transactions. By collaborating with us, you can provide instant, cardless, and hassle-free credit solutions that cater to every customer’s needs.
          </p>

          {/* Why Partner with CleverPe Section */}
          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Why Partner with CleverPe?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="partner-card p-6 bg-white shadow-lg rounded-lg">
              <h4 className="text-xl font-semibold mb-2">Merchants</h4>
              <p className="text-gray-600 text-justify">
                Increase sales by offering seamless payment options like Buy Now, Pay Later (BNPL) and No Cost EMIs. Attract more customers, retain loyalty, and create an exceptional shopping experience.
              </p>
            </div>
            <div className="partner-card p-6 bg-white shadow-lg rounded-lg">
              <h4 className="text-xl font-semibold mb-2">Multi-Chain Brands & OEMs</h4>
              <p className="text-gray-600 text-justify">
                Streamline payments across all outlets and empower your customers with flexibility. Drive innovation and enhance brand value with cutting-edge financial solutions.
              </p>
            </div>
            <div className="partner-card p-6 bg-white shadow-lg rounded-lg">
              <h4 className="text-xl font-semibold mb-2">EV Dealers</h4>
              <p className="text-gray-600 text-justify">
                Make eco-friendly mobility affordable. Offer electric bike loans of up to ₹2,00,000 with a 100% digital and paperless process, attracting new-age customers to join the green revolution.
              </p>
            </div>
          </div>

          {/* What You Get Section */}
          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">What You Get</h2>
          <ul className="list-disc pl-6 text-lg text-gray-700 mb-8">
            <li><strong>Increased Sales:</strong> Customers are more likely to complete their purchases with interest-free and flexible payment plans.</li>
            <li><strong>Seamless Integration:</strong> Easy setup and integration with CleverPe’s digital solutions for omnichannel convenience.</li>
            <li><strong>Customer Satisfaction:</strong> Build trust and loyalty by providing hassle-free, cardless credit options.</li>
          </ul>

          {/* Call to Action Section */}
          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Let’s Revolutionize Together</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            We believe in partnerships that bring growth and value. By working with CleverPe, you’ll gain access to cutting-edge payment technology, a growing customer base, and the tools to stand out in today’s competitive market.
          </p>
          <p className="text-gray-700 text-base leading-relaxed text-justify">
            Join the payment revolution today! Let’s create value for your business and your customers.
          </p>
        </div>
        <div className="mt-16 mb-12 text-center">
  <div className="max-w-2xl mx-auto bg-gradient-to-br from-purple-50 to-blue-50 p-8 rounded-2xl shadow-lg">
    <h2 className="text-3xl font-bold text-gray-900 mb-4">
      Ready to Get Started?
    </h2>
    <p className="text-gray-600 mb-8">
      Join thousands of businesses that trust CleverPe for their payment solutions. 
      Start your journey today!
    </p>

    <div className="flex flex-col sm:flex-row gap-4 justify-center">
      {/* Onboard as Merchant Button */}
      <a
        href="/contactus"
        className="group inline-flex items-center justify-center gap-2 px-6 py-3 bg-white border-2 border-purple-600 text-purple-600 rounded-lg font-medium transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/20 hover:-translate-y-0.5"
      >
        <Store className="w-5 h-5" />
        <span>Contact Sales Team</span>
        <ArrowRight className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
      </a>
    </div>

    <p className="mt-6 text-sm text-gray-500">
      Have questions? Email us at{" "}
      <a 
        href="mailto:support@cleverpe.com"
        className="text-purple-600 hover:text-purple-700 font-medium"
      >
        support@cleverpe.com
      </a>
    </p>
  </div>
</div>

      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default PartnerWithCleverPe;
