import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";
import { Quote, Mail, ArrowRight } from "lucide-react";


const Careers = () => {
  const noOpeningsRef = useRef(null);
  const quoteRef = useRef(null);
  const cardRef = useRef(null);
  const ctaRef = useRef(null);

  useEffect(() => {
    if (noOpeningsRef.current && quoteRef.current && cardRef.current) {
      // Animate "No Openings" text
      gsap.fromTo(
        noOpeningsRef.current,
        { scaleY: 0, opacity: 0, transformOrigin: "top center" },
        { scaleY: 1, opacity: 1, duration: 1.5, ease: "power2.out", delay: 0.5 }
      );

      // Create floating animation for the card
      gsap.to(cardRef.current, {
        y: -20,
        duration: 2,
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut"
      });

      // Animate quote card appearance
      gsap.fromTo(
        quoteRef.current,
        { 
          scale: 0.8,
          opacity: 0,
          rotate: -5
        },
        { 
          scale: 1,
          opacity: 1,
          rotate: 0,
          duration: 1.5,
          ease: "elastic.out(1, 0.75)",
          delay: 1
        }
      );

      gsap.fromTo(
        ctaRef.current,
        { y: 30, opacity: 0 },
        { y: 0, opacity: 1, duration: 1, ease: "power2.out", delay: 1.5 }
      );
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      <main className="flex-grow flex flex-col items-center justify-center min-h-screen text-center px-6 pt-10">
        <h1
          className="text-4xl font-bold text-gray-900 opacity-0 pt-3"
          ref={noOpeningsRef}
          style={{ transformStyle: "preserve-3d" }}
        >
          No Openings Now!
        </h1>
        
        <div 
          ref={cardRef}
          className="relative mt-12 mb-8 w-full max-w-xl"
        >
          <div
            ref={quoteRef}
            className="relative p-8 rounded-2xl bg-gradient-to-br from-purple-600 to-blue-500 shadow-xl opacity-0"
          >
            {/* Decorative elements */}
            <div className="absolute top-4 left-4 text-white/20">
              <Quote size={48} />
            </div>
            <div className="absolute -top-4 -right-4 w-24 h-24 bg-purple-400 rounded-full opacity-20 blur-xl"></div>
            
            {/* Quote content */}
            <div className="relative z-10">
              <blockquote className="text-2xl font-medium text-white leading-relaxed">
                "The future belongs to those who believe in the beauty of their dreams."
              </blockquote>
              <div className="mt-4 flex items-center justify-end">
                <div className="h-px w-12 bg-white/30 mr-4"></div>
                <p className="text-white/90 font-light">Eleanor Roosevelt</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 font-regular max-w-lg text-lg text-gray-700">
          <p>
            We appreciate your interest in joining our team. While there are no current openings,
            we encourage you to check back later for new opportunities.
          </p>
          <p className="mt-4">
            In the meantime, explore our company and learn more about our culture, values, and the
            exciting work we do. Visit our website to get a deeper insight into our mission.
          </p>
        </div>

        {/* New CTA section */}
<div 
  ref={ctaRef} 
  className="mt-16 mb-12 text-center opacity-0"
>
  <div className="mb-6">
    <h2 className="text-2xl font-semibold text-gray-800 mb-2">
      Showcase Your Potential
    </h2>
    <p className="text-gray-600 max-w-2xl mx-auto">
      Even though we don't have current openings, we're always excited to connect with talented individuals. 
      Share your story and expertise with us!
    </p>
  </div>

  
  <a 
    href="mailto:joinus@cleverpe.com"
    className="group inline-flex items-center gap-2 px-6 py-3 bg-gradient-purple text-white rounded-lg font-medium transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/30 hover:-translate-y-0.5"
  >
    <Mail className="w-5 h-5" />
    <span>Reach Out to Us</span>
    <ArrowRight className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
  </a>
</div>

      </main>

      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default Careers;