import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '@/pages/home/HomePage';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import PartnerWithCleverPe from '@/pages/PartnerWithUs';
import TermsAndConditions from '@/pages/TnC';
import EmiGuide from '@/pages/EmiGuides';
import ComplaintsPage from '@/pages/Complaints';
import FAQs from '@/pages/FAQs';
import ContactUs from '@/pages/ContactUs';
import LendingPartners from '@/pages/LendingPartners';
import Careers from '@/pages/Careers';
import MarketPlace from '@/pages/MarketPlace';
import AboutUs from '@/pages/AboutUs';
import NoCostEMI from '@/pages/NoCostEmi';
import BnPl from '@/pages/BnPl';
import MerchantLocater from '@/pages/MerchantLocater';

const App = () => {
  return (
    <Router>
      <div className="min-h-screen overflow-x-hidden">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/partnerwithus" element={<PartnerWithCleverPe />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/emiguide" element={<EmiGuide />} />
        <Route path="/complaints" element={<ComplaintsPage />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/lendingpartners" element={<LendingPartners />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/nocostemi" element={<NoCostEMI />} />
        <Route path="/bnpl" element={<BnPl />} />
        {/* <Route path="/merchantlocater" element={<MerchantLocater />} /> */}
      </Routes>
      </div>
    </Router>
  );
};

export default App;
