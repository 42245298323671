import React, { useEffect, useState } from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const MarketPlace = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content with white background */}
      <main className="flex-grow px-4 sm:px-8 py-8 sm:py-12">
        <div className="max-w-screen-lg mx-auto">
          <h1 className="text-4xl sm:text-6xl font-bold text-center text-gray-900 mb-8 sm:mb-12">
            MarketPlace
          </h1>
          {/* Responsive Banner */}
          <div className="relative h-[60vh] sm:h-[80vh] w-full rounded-2xl sm:rounded-3xl bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 overflow-hidden flex items-center justify-center shadow-2xl">
            {/* Responsive background elements */}
            <div className="absolute inset-0">
              <div className="absolute w-[20rem] sm:w-[40rem] h-[20rem] sm:h-[40rem] bg-purple-600/20 rounded-full blur-3xl animate-pulse-slow top-[-20%] left-[-10%]" />
              <div className="absolute w-[15rem] sm:w-[30rem] h-[15rem] sm:h-[30rem] bg-blue-600/20 rounded-full blur-3xl animate-pulse-fast bottom-[-20%] right-[-10%]" />
              <div className="absolute w-[17rem] sm:w-[35rem] h-[17rem] sm:h-[35rem] bg-indigo-600/20 rounded-full blur-3xl animate-pulse top-[50%] left-[20%]" />
            </div>
          
            <div className="absolute inset-0 bg-grid opacity-20" />
          
            <div className={`relative transform transition-all duration-1000 ease-out ${
              isVisible ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-full opacity-0 scale-90'
            }`}>
              <div className={`transform perspective-1000 transition-all duration-1000 delay-300 ${
                isVisible ? 'rotate-x-0 translate-y-0' : 'rotate-x-90 translate-y-10'
              }`}>
                <div className="text-white text-5xl sm:text-8xl font-bold mb-2 text-center bg-clip-text text-transparent bg-gradient-to-r from-white via-white/90 to-white/80">
                  Coming
                </div>
              </div>
            
              <div className={`transform perspective-1000 transition-all duration-1000 delay-500 ${
                isVisible ? 'rotate-x-0 translate-y-0' : '-rotate-x-90 translate-y-10'
              }`}>
                <div className="text-white text-5xl sm:text-8xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-white via-white/90 to-white/80">
                  Soon
                </div>
              </div>
            
              <div className={`transition-all duration-1000 delay-700 ${
                isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
              }`}>
                <p className="text-white/90 text-lg sm:text-2xl mt-8 sm:mt-12 text-center font-light tracking-wider px-4">
                  Something extraordinary is being crafted just for you
                </p>
              </div>
            
              <div className="mt-8 sm:mt-16 flex justify-center gap-4 sm:gap-6">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="relative">
                    <div
                      className={`h-1 sm:h-1.5 w-16 sm:w-24 bg-white/80 rounded-full transition-all duration-700 shadow-glow ${
                        isVisible ? 'scale-x-100' : 'scale-x-0'
                      }`}
                      style={{ transitionDelay: `${1300 + i * 100}ms` }}
                    />
                    <div
                      className={`absolute top-0 h-1 sm:h-1.5 w-16 sm:w-24 bg-white/30 rounded-full blur-md transition-all duration-700 ${
                        isVisible ? 'scale-x-100' : 'scale-x-0'
                      }`}
                      style={{ transitionDelay: `${1400 + i * 100}ms` }}
                    />
                  </div>
                ))}
              </div>
            </div>
          
            {[...Array(20)].map((_, i) => (
              <div
                key={i}
                className="absolute rounded-full animate-float"
                style={{
                  width: `${Math.random() * 3 + 1}px`,
                  height: `${Math.random() * 3 + 1}px`,
                  background: `rgba(255, 255, 255, ${Math.random() * 0.3 + 0.1})`,
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  animationDuration: `${5 + Math.random() * 10}s`,
                  animationDelay: `-${Math.random() * 5}s`
                }}
              />
            ))}
          </div>
        </div>
      </main>

      <Footer bgColor="bg-[#2F2E2E]" />

      <style>
        {`
          @keyframes float {
            0% {
              transform: translateY(0) translateX(0);
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            100% {
              transform: translateY(-100vh) translateX(50px);
              opacity: 0;
            }
          }

          .animate-float {
            animation: float linear infinite;
          }

          .animate-pulse-slow {
            animation: pulse 6s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          }

          .animate-pulse-fast {
            animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          }

          .shadow-glow {
            box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
          }

          .perspective-1000 {
            perspective: 1000px;
          }

          .bg-grid {
            background-image: linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px),
                            linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
            background-size: 40px 40px;
          }
        `}
      </style>
    </div>
  );
};

export default MarketPlace;