import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";
import { Mail, Phone } from "lucide-react";

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-6xl font-bold text-gray-900 mb-6">
              Terms and Conditions
            </h1>
            <div className="bg-gradient-to-r from-purple-50 to-white p-6 rounded-xl">
              <p className="text-gray-700">
                By accessing or using our website, services, or products, you agree to be bound by these Terms and Conditions, as well as any additional policies referenced herein.
              </p>
            </div>
          </div>

          {/* Terms Sections */}
          <div className="space-y-12">
            {/* Definitions */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">1. Definitions</h2>
              <div className="space-y-4">
                <p className="text-gray-700">
                  <strong className="text-purple-600">CleverPe</strong> refers to our company, platform, services, and any affiliated entities.
                </p>
                <p className="text-gray-700">
                  <strong className="text-purple-600">User</strong> refers to anyone accessing or using our services, including merchants, customers, OEMs, and partners.
                </p>
                <p className="text-gray-700">
                  <strong className="text-purple-600">Services</strong> include credit facilitation, financing options, marketplace features, and all associated tools.
                </p>
              </div>
            </section>

            {/* Eligibility */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">2. Eligibility</h2>
              <div className="space-y-2">
                <p className="text-gray-700">You must:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-700">
                  <li>Be at least 18 years of age.</li>
                  <li>Provide accurate and complete information during the registration process.</li>
                  <li>Agree not to use CleverPe for fraudulent or illegal activities.</li>
                </ul>
              </div>
            </section>

            {/* Use of Services */}
            <section className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">3. Use of Services</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="font-semibold text-purple-600 mb-2">Merchants and Partners:</h3>
                  <p className="text-gray-700">Ensure compliance with all applicable laws and regulations in offering CleverPe services to your customers.</p>
                </div>
                <div>
                  <h3 className="font-semibold text-purple-600 mb-2">Customers:</h3>
                  <p className="text-gray-700">Use our services responsibly and repay any credits availed as per agreed terms.</p>
                </div>
                <p className="text-gray-700">CleverPe reserves the right to suspend or terminate your access if you misuse our services or violate these terms.</p>
              </div>
            </section>

            {/* Privacy and Data Usage */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">4. Privacy and Data Usage</h2>
              <p className="text-gray-700">
                Your privacy is important to us. By using our services, you consent to the collection, storage, and use of your information as outlined in our Privacy Policy. CleverPe ensures that your data is handled securely and only used to provide and improve our services.
              </p>
            </section>

            {/* Payment and Credit Terms */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">5. Payment and Credit Terms</h2>
              <p className="text-gray-700">
                Any credit extended through CleverPe is subject to eligibility criteria and approval. Users must adhere to the repayment terms provided at the time of credit approval. Late payments or defaults may result in penalties or restrictions on future usage.
              </p>
            </section>

            {/* Intellectual Property */}
            <section className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">6. Intellectual Property</h2>
              <p className="text-gray-700">
                All content on the CleverPe platform, including but not limited to logos, text, graphics, and software, is the property of CleverPe. Unauthorized use, reproduction, or distribution is prohibited.
              </p>
            </section>

            {/* Limitation of Liability */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">7. Limitation of Liability</h2>
              <p className="text-gray-700 mb-4">CleverPe is not liable for:</p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Losses arising from unauthorized use of your account.</li>
                <li>Any indirect, incidental, or consequential damages related to our services.</li>
                <li>Delays, interruptions, or errors beyond our control.</li>
              </ul>
            </section>

            {/* Modification of Terms */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">8. Modification of Terms</h2>
              <p className="text-gray-700">
                CleverPe reserves the right to update or modify these terms at any time. Users will be notified of significant changes, and continued use of our services constitutes acceptance of updated terms.
              </p>
            </section>

            {/* Termination of Services */}
            <section className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">9. Termination of Services</h2>
              <p className="text-gray-700">
                We may terminate or restrict access to our services at our discretion if users violate these terms or engage in unlawful activities.
              </p>
            </section>

            {/* Governing Law */}
            <section className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">10. Governing Law</h2>
              <p className="text-gray-700">
                These terms are governed by and construed in accordance with the laws of India. Any disputes shall be resolved under the exclusive jurisdiction of the courts in Bengaluru.
              </p>
            </section>

            {/* Contact Section */}
            <section className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">11. Contact Us</h2>
              <p className="text-gray-700 mb-4">For questions or concerns regarding these Terms and Conditions, contact us at:</p>
              <div className="space-y-2">
                <div className="flex items-center">
                  <Mail className="text-purple-600 mr-3" size={20} />
                  <a href="mailto:support@cleverpe.com" className="text-gray-700 hover:text-purple-600">
                    support@cleverpe.com
                  </a>
                </div>
                {/* <div className="flex items-center">
                  <Phone className="text-purple-600 mr-3" size={20} />
                  <span className="text-gray-700">+91 85598 96607</span>
                </div> */}
              </div>
            </section>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default TermsAndConditions;