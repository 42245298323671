import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormStep from './FormStep';
import OtpStep from './OtpStep';
import ResultStep from './ResultStep';

const CreditLimitChecker: React.FC = () => {
  const [step, setStep] = useState<'form' | 'otp' | 'result'>('form');
  const [customerName, setcustomerName] = useState('');
  const [mobileNo, setmobileNo] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [otpHash, setOtpHash] = useState(null);
  const [amount, setCreditLineAmount] = useState<number | null>(null);
  const [isFirstChecked, setIsFirstChecked] = useState(false);
  const [isSecondChecked, setIsSecondChecked] = useState(false);
  const [creditLineStatus, setCreditLineStatus] = useState<'success' | 'failure' | null>(null);
  const [timer, setTimer] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [url] = useState(window.location.href);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setResendDisabled(false);
    }
  }, [timer]);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "🚀Check Your Credit Limit Instantly!",
          text: "Want to know your eligible credit limit? Use CleverPe's Credit Limit Checker to get instant insights. Click the link and check now! ⚡💳",
          url: url,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      navigator.clipboard.writeText(url);
      alert("Link copied to clipboard!");
    }
  };

  // Desktop: Function to handle OTP change and focus on the next field
  const handleOtpChange = (value: string, index: number) => {
    const updatedOtp = [...otp];
  
    // Allow only numeric input (0-9)
    if (/^\d?$/.test(value)) {
      updatedOtp[index] = value;
      setOtp(updatedOtp);
  
      // Move focus to the next field if a digit is entered
      if (value && index < otp.length - 1) {
        setTimeout(() => {
          const nextInput = document.getElementById(`otp-${index + 1}`);
          if (nextInput) {
            nextInput.focus();
          }
        }, 10); // Small delay to ensure DOM is updated
      } 
      // Move focus to the previous field if backspace is pressed and the current field is empty
      else if (!value && index > 0) {
        setTimeout(() => {
          const prevInput = document.getElementById(`otp-${index - 1}`);
          if (prevInput) {
            prevInput.focus();
          }
        }, 10); // Small delay to ensure DOM is updated
      }
    }
  };
  
  
  
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);

    if (step === 'form') {
      if (!customerName.trim()) {
        setErrorMessage("Please enter your full name as per PAN.");
        return;
      }

      if (!mobileNo.trim()) {
        setErrorMessage("Please enter your mobile number.");
        return;
      }

      if (!/^\d{10}$/.test(mobileNo)) {
        setErrorMessage("Please enter a valid 10-digit mobile number.");
        return;
      }

      if (!isFirstChecked || !isSecondChecked) {
        setErrorMessage("Please accept both terms to proceed.");
        return;
      }

      try {
        const response = await axios.post('https://prod.cleverpe.com/b2b/cashe/pre-approval/get-otp', {
          customerName,
          mobileNo,
        });

        if (response.status === 200 && response.data.result?.otpHash) {
          setOtpHash(response.data.result.otpHash);
          setStep('otp');
          setTimer(60);
          setResendDisabled(true);
        }
      } catch (error: any) {
        if (error.response) {
          const { status, result } = error.response.data;
          switch (status) {
            case 400:
              setErrorMessage(result || "Invalid phone number.");
              break;
            case 500:
              setErrorMessage(result || "Something went wrong. Please try again.");
              break;
            default:
              setErrorMessage(`Unexpected Error: Please try again`);
          }
        } else {
          setErrorMessage("Network error. Please check your connection and try again.");
        }
      }
    } else if (step === 'otp') {
      try {
        setIsLoading(true);
        const otpCode = otp.join('');
    
        const response = await axios.post('https://prod.cleverpe.com/b2b/cashe/pre-approval/verify-otp', {
          mobileNo,
          otp: otpCode,
          otpHash,
        });
    
        if (response.status === 200) {
          const { amount, status } = response.data.result;
          setCreditLineAmount(amount);
    
          await new Promise(resolve => setTimeout(resolve, 2000));
    
          if (status === "rejected") {
            setCreditLineStatus('failure');
          } else {
            setCreditLineStatus('success');
          }
          setStep('result');
        }
      } catch (error: any) {
        if (error.response) {
          console.log("Full Error Response:", error.response.data); // Debugging log
      
          const responseData = error.response.data || {}; // Ensure responseData is an object
          const statusCode = error.response.status; // Get HTTP status code
          let resultMessage = responseData.result || responseData.message; // Try both `result` and `message`
      
          if (!resultMessage || resultMessage.toLowerCase() === "none") {
            resultMessage = "Invalid OTP. Please try again."; // Set fallback message
          }
      
          let errorMessage = "An unexpected error occurred. Please try again."; // Default message
      
          switch (statusCode) {
            case 400:
              errorMessage = resultMessage;
              break;
      
            case 401:
              console.log("401 Error - Response Data:", responseData); // Debugging log
      
              if (resultMessage.toLowerCase().includes("otp expired")) {
                errorMessage = "OTP expired. Please request a new OTP.";
                setOtp(Array(6).fill(""));
                setStep("form"); // Redirect back to OTP input
              } else {
                errorMessage = "Invalid OTP. Please try again.";
              }
              break;
      
            case 403:
              errorMessage = resultMessage;
              break;
      
            case 500:
              errorMessage = resultMessage;
              break;
      
            default:
              errorMessage = resultMessage;
          }
      
          console.log("Final Error Message:", errorMessage); // Debugging log
          setErrorMessage(errorMessage);
        } else {
          // If no response, it's likely a network issue
          setErrorMessage("Network error. Please check your connection and try again.");
        }
      } finally {
        setIsLoading(false);
      }
    }    
  };

  const handleResendOtp = async () => {
    if (!isFirstChecked || !isSecondChecked) {
      setErrorMessage("Please accept both terms to proceed.");
      return;
    }

    setResendDisabled(true);
    setTimer(60);
    setOtp(Array(6).fill(""));

    try {
      const response = await axios.post('https://prod.cleverpe.com/b2b/cashe/pre-approval/get-otp', {
        customerName,
        mobileNo,
      });

      if (response.status === 200 && response.data.result?.otpHash) {
        setOtpHash(response.data.result.otpHash);
        setStep('otp');
        setErrorMessage(null);
      }
    } catch (error: any) {
      if (error.response) {
        const { status, result } = error.response.data;
        switch (status) {
          case 400:
            setErrorMessage(result || "Invalid phone number.");
            break;
          case 500:
            setErrorMessage(result || "Something went wrong. Please try again.");
            break;
          default:
            setErrorMessage("OTP expired. Please request a new OTP.");
        }
      } else {
        setErrorMessage("Network error. Please check your connection and try again.");
      }
    }
  };  

  return (
    <section id="credit-limit-section" className="relative z-10 py-5 bg-transparent">
      <div className="max-w-[1200px] mx-auto px-4">
        <div className="flex flex-col">
          <div className="text-center mb-12">
            <h2 className="text-[44px] sm:text-[32px] md:text-[38px] font-bold leading-[60px] sm:leading-[48px] md:leading-[55px] tracking-normal text-gradient-light">
              Check Your Credit Limit In Seconds
            </h2>
            <p className="mt-4 text-[20px] sm:text-[14px] md:text-[20px] leading-[24px] sm:leading-[20px] md:leading-[23px] font-medium max-w-[900px] mx-auto text-gradient-light">
              Find Out Your Eligibility Instantly! Enter Your Name (As Per PAN) And
              Mobile Number To Check Your Credit Limit In Just Seconds - No
              Documents, No Stress
            </p>
          </div>

          <div className="bg-gradient-dark rounded-[20px] p-10 shadow-lg mx-auto w-full sm:w-[1050px] min-h-[auto] sm:h-[260px] flex flex-col sm:flex-row items-center justify-between transition-all duration-500">
            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-center sm:items-stretch w-full transition-transform duration-500">
              {step === 'form' && (
                <FormStep
                  customerName={customerName}
                  setcustomerName={setcustomerName}
                  mobileNo={mobileNo}
                  setmobileNo={setmobileNo}
                  isFirstChecked={isFirstChecked}
                  setIsFirstChecked={setIsFirstChecked}
                  isSecondChecked={isSecondChecked}
                  setIsSecondChecked={setIsSecondChecked}
                  errorMessage={errorMessage}
                />
              )}
              {step === 'otp' && (
                <OtpStep
                  otp={otp}
                  handleOtpChange={handleOtpChange}
                  errorMessage={errorMessage}
                  timer={timer}
                  resendDisabled={resendDisabled}
                  handleResendOtp={handleResendOtp}
                  isLoading={isLoading}
                />
              )}
              {step === 'result' && (
                <ResultStep
                  creditLineStatus={creditLineStatus}
                  customerName={customerName}
                  amount={amount}
                  handleShare={handleShare}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditLimitChecker;