import React, { useState } from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";
import { ChevronDown, ChevronUp, Mail, Phone, MessageSquare } from "lucide-react";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState<string | null>(null);


  const faqs = [
    {
      category: "About CleverPe",
      questions: [
        {
          q: "What is CleverPe?",
          a: "CleverPe is a payment solutions platform that offers cardless, instant, and hassle-free affordability options like Buy Now Pay Later (BNPL), No Cost EMIs, and Electric Vehicle Financing to help customers manage payments more conveniently."
        }
      ]
    },
    {
      category: "Getting Started",
      questions: [
        {
          q: "How do I check my credit limit?",
          a: "You can check your credit limit instantly by visiting our website and entering your Name (as per PAN) and Mobile Number. You'll receive an instant decision without any documents required."
        },
        {
          q: "How can I apply for BNPL or No Cost EMI?",
          a: "To apply for BNPL or No Cost EMIs, simply sign up on the CleverPe platform. Once you're approved, you can start using the service at any participating merchants. There's no need for credit cards or complicated paperwork."
        },
        {
          q: "How long does it take to get approved for credit?",
          a: "Approval for your credit limit takes just 2 minutes. After entering your Name and Mobile Number, you'll get an instant decision with no documentation required."
        }
      ]
    },
    {
      category: "Services & Features",
      questions: [
        {
          q: "Can I use CleverPe for online and offline purchases?",
          a: "Yes! CleverPe is omnichannel, meaning you can use it for both online and offline purchases at partner merchants. Whether you're shopping on a website or in-store, CleverPe offers the flexibility to pay through instant credit."
        },
        {
          q: "What are the benefits of Electric Vehicle (EV) financing?",
          a: "CleverPe offers EV financing with loans of up to ₹2,00,000 for electric bikes. The process is fully digital, paperless, and offers low-interest rates with flexible repayment options."
        },
        {
          q: "Is there an interest on Buy Now Pay Later (BNPL)?",
          a: "No, the BNPL option comes with zero interest if paid within the agreed time frame. There are no hidden charges or fees as long as you repay within the terms of the plan."
        }
      ]
    },
    {
      category: "Documentation & Requirements",
      questions: [
        {
          q: "Do I need to provide income proof or KYC?",
          a: "No, CleverPe offers a hassle-free process with no need for income proof, KYC, or demographic details. The process is fast and paperless."
        },
        {
          q: "Can I increase my credit limit?",
          a: "To increase your credit limit, please contact our customer support team, and they will guide you through the available options. You may also be eligible for a credit limit increase based on your usage history."
        }
      ]
    },
    {
      category: "For Merchants",
      questions: [
        {
          q: "Is CleverPe available for merchants?",
          a: "Yes! CleverPe offers merchants a variety of payment solutions that enhance customer experiences and increase sales. Our services are designed to integrate seamlessly with your business, whether you are online or offline."
        },
        {
          q: "How do I become a CleverPe merchant?",
          a: "To become a merchant, simply visit the \"Partner with Us\" page on our website and fill out the registration form. Our team will get in touch with you to guide you through the process."
        }
      ]
    },
    {
      category: "Support & Coverage",
      questions: [
        {
          q: "How do I contact CleverPe customer support?",
          a: "You can reach us via email at support@cleverpe.com, phone at +91 85598 96607 (9 AM – 7 PM, Monday to Saturday), or use the live chat feature on our website."
        },
        {
          q: "What if I have a complaint or issue with my account?",
          a: "If you have any concerns, you can file a complaint using our online form, via email at support@cleverpe.com, or by calling our support team. Our team will aim to resolve your issue promptly."
        },
        {
          q: "Can I use CleverPe in multiple cities?",
          a: "Yes, CleverPe is available in multiple cities across India, and we are constantly expanding. You can check if our services are available in your city by visiting our website or contacting our support team."
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-6xl font-bold text-gray-900 mb-6">
              FAQ
            </h1>
            <h2 className="text-2xl font-bold text-purple-600 mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-700">
              Find answers to common questions about CleverPe
            </p>
          </div>

          {/* Support Contact Cards */}
          <div className="grid md:grid-cols-1 gap-6 mb-12">
            <div className="bg-purple-50 p-6 rounded-lg text-center ">
              <Mail className="mx-auto mb-4 text-purple-600" size={24} />
              <h3 className="font-semibold mb-2">Email Support</h3>
              <a href="mailto:support@cleverpe.com" className="text-sm text-purple-600 hover:underline">
                support@cleverpe.com
              </a>
            </div>
            
            {/* <div className="bg-purple-50 p-6 rounded-lg text-center">
              <Phone className="mx-auto mb-4 text-purple-600" size={24} />
              <h3 className="font-semibold mb-2">Phone Support</h3>
              <a href="tel:+918559896607" className="text-sm text-purple-600 hover:underline">
                +91 85598 96607
              </a>
            </div> */}
            
            {/* <div className="bg-purple-50 p-6 rounded-lg text-center">
              <MessageSquare className="mx-auto mb-4 text-purple-600" size={24} />
              <h3 className="font-semibold mb-2">Live Chat</h3>
              <p className="text-sm text-gray-600">Available on website</p>
            </div> */}
          </div>

          {/* FAQ Categories */}
          {faqs.map((category, categoryIndex) => (
            <div key={category.category} className="mb-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                {category.category}
              </h2>
              <div className="space-y-4">
                {category.questions.map((faq, index) => (
                  <div 
                    key={index}
                    className="bg-white rounded-lg shadow-lg overflow-hidden"
                  >
                    <button
                      className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50"
                      onClick={() => setOpenIndex(openIndex === `${categoryIndex}-${index}` ? null : `${categoryIndex}-${index}`)}
                    >
                      <span className="font-semibold text-gray-900">{faq.q}</span>
                      {openIndex === `${categoryIndex}-${index}` ? (
                        <ChevronUp className="text-purple-600" size={20} />
                      ) : (
                        <ChevronDown className="text-purple-600" size={20} />
                      )}
                    </button>
                    {openIndex === `${categoryIndex}-${index}` && (
                      <div className="px-6 py-4 bg-purple-50">
                        <p className="text-gray-700">{faq.a}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* CTA Section */}
          <div className="text-center py-8 bg-gradient-to-r from-purple-50 to-white rounded-xl mt-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Still have questions?
            </h2>
            <p className="text-gray-700 mb-6">
              Our support team is here to help you with any queries you might have.
            </p>
            <a
              href="/contactus"
              className="inline-block px-8 py-4 bg-gradient-purple text-white font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5] transform hover:scale-105 transition-transform"
            >
              Contact Support
            </a>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default FAQs;