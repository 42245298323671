import React from "react";
import centerLeaf from '/src/assets/icon/CenterLeaf.svg';
import EcoEllipse from '/src/assets/icon/EcoEllipse.png';
import centerLeaf2 from '/src/assets/icon/CenterLeaf2.svg';
import EcoEllipse2 from '/src/assets/icon/EcoEllipse2.png';

const EVSimple: React.FC = () => {
  return (
    <div className="w-full h-[780px] md:h-[450px] bg-radial-gradient-green mt-12 z-10 relative pb-12 md:pb-0">
      <div className="w-full text-white text-4xl md:text-6xl font-bold text-center pt-[40px]">
        Simple. Easy. Quick
      </div>

      <div className="flex flex-col md:flex-row justify-between mt-[80px] px-4 md:px-16 relative items-center md:items-start">
        {/* 1st Numbering Section */}
        <div className="text-center flex-1">
          <div className="text-white text-[50px] md:text-[75px] font-bold">1</div>
          <div className="text-white text-[20px] md:text-[24px] font-bold capitalize leading-[28px] mt-2">
            Choose your Favorite EV
          </div>
          <div className="w-[240px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent mx-auto my-4"></div>
          <div className="text-white text-[16px] md:text-[18px] font-medium leading-tight">
            Visit the nearest EV dealer partnered<br className="hidden md:block" />
            with us.
          </div>
        </div>

        {/* 2nd Numbering Section */}
        <div className="text-center flex-1 relative mt-12 md:mt-0">
          {/* Desktop SVG Images */}
          <img
            className="absolute hidden md:block top-[-150px] left-1/2 transform -translate-x-1/2 w-[1300px] h-[400px] opacity-60 mix-blend-lighten filter brightness-125"
            src={centerLeaf}
            alt="Semicircular Arc"
          />
          <img
            className="absolute hidden md:block top-[-150px] left-1/2 transform -translate-x-1/2 w-[1300px] h-[400px] opacity-60 mix-blend-lighten filter brightness-125"
            src={EcoEllipse}
            alt="Semicircular Ellipse"
          />

          {/*Images for Mobile */}
          <img
            className="absolute md:hidden top-[-320px]  -1/2 transform -translate-x-1/2 w-[1000px] h-[700px] opacity-60 mix-blend-lighten filter brightness-125"
            src={centerLeaf2}
            alt="Semicircular Arc"
          />
          
          <img
            className="absolute md:hidden w-[500px] h-[250px] opacity-60 mix-blend-lighten filter brightness-125"
            src={EcoEllipse2}
            alt="Ellipse"
          />

          <div className="relative z-10">
            <div className="text-white text-[50px] md:text-[75px] font-bold">2</div>
            <div className="text-white text-[20px] md:text-[24px] font-bold capitalize leading-[28px] mt-2">
              Register & buy using CleverPe
            </div>
            <div className="w-[240px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent mx-auto my-4"></div>
            <div className="text-white text-[16px] md:text-[18px] font-medium leading-tight">
              Complete the loan journey within<br className="hidden md:block" />
              few minutes
            </div>
          </div>
        </div>

        {/* 3rd Numbering Section */}
        <div className="text-center flex-1 mt-12 md:mt-0 md:ml-0 sm:ml-[20px]">
          <div className="text-white text-[50px] md:text-[75px] font-bold">3</div>
          <div className="text-white text-[20px] md:text-[24px] font-bold capitalize leading-[28px] mt-2">
            Ride your EV home!
          </div>
          <div className="w-[240px] h-[1px] bg-gradient-to-r from-transparent via-white to-transparent mx-auto my-4"></div>
          <div className="text-white text-[16px] md:text-[18px] font-medium leading-tight">
            Enjoy your ride!
          </div>
        </div>
      </div>
    </div>
  );
};

export default EVSimple;