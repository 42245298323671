import React from 'react';
import Logo from "../../assets/logo/Eco_H.png";
import LeftLeaf from '../../assets/icon/EcoLeaf.png';
import RightLeaf from '../../assets/icon/EcoLeaf2.png';

const EVHero = () => {
  return (
    <div className="relative w-full min-h-[350px] sm:min-h-screen overflow-hidden">
      {/* Left Top Leaf Image */}
      <div className="absolute left-5 top-5 sm:top-20 -translate-x-1/4 -translate-y-1/4">
        <img src={LeftLeaf} alt="Decorative leaf" className="w-20 sm:w-96 h-auto" />
      </div>

      {/* Right Bottom Leaf Image */}
      <div className="absolute right-0 bottom-0 translate-x-1/4 translate-y-1/4">
        <img src={RightLeaf} alt="Decorative leaf" className="w-40 sm:w-96 h-auto" />
      </div>

      {/* Main Content */}
      <div className="relative z-10 max-w-6xl mx-auto px-4 py-16 sm:py-24 mt-2 sm:mt-4">
        {/* Logo */}
        <div className="flex justify-center mt-12 sm:mt-0 mb-14 sm:mb-8">
          <img src={Logo} alt="CleverPe Eco" className="w-68 sm:w-[750px] h-auto object-contain" />
        </div>

        {/* Main Heading */}
        <h1 className="text-center text-3xl sm:text-5xl font-bold mb-3 sm:mb-6 mt-16 sm:mt-10 ml-3 sm:ml-0 leading-tight sm:leading-snug">
          <span className="bg-gradient-green text-transparent bg-clip-text">
            Electric Vehicle Financing
          </span>
        </h1>

        {/* Subheading */}
        <p className="font-medium text-center text-sm sm:text-xl max-w-[90%] sm:max-w-4xl mx-auto leading-snug sm:leading-relaxed mb-3 sm:mb-16 
           bg-gradient-green text-transparent bg-clip-text">
          Join The Eco-Friendly Revolution With CleverPe. Get Loans Of Up To ₹2,00,000 For Your Dream
          Electric Bike In Just 30 Minutes. Enjoy A 100% Digital And Paperless Process.
        </p>
      </div>
    </div>
  );
};

export default EVHero;