import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          <h1 className="text-6xl font-bold text-center text-gray-900 mb-8">Privacy Policy</h1>

          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            This Privacy Policy describes how <strong>CLEVERPE TECHNOLOGIES PRIVATE LIMITED</strong> collects and uses the personal information you provide when you use our services or visit our website at www.cleverpe.com.
          </p>
          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            Line of credit available through <strong>CLEVERPE TECHNOLOGIES PRIVATE LIMITED</strong> are provided by our partner banks. Personal information connected to the application is shared with the banks.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Changes To CleverPe Privacy Policy:</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            We may update our Privacy Policy from time to time. If changed, these changes will be reflected on our website and the date of the last change displayed on the top of the page. Your continued use of <strong>CLEVERPE TECHNOLOGIES PRIVATE LIMITED</strong> services or the website after we have made such changes will constitute your acceptance of the new Privacy Policy.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Information We Collect About You:</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            When you register to use <strong>CLEVERPE TECHNOLOGIES PRIVATE LIMITED</strong> services by applying for a line of credit through partner merchant websites or through our website, we collect your name, email-id, mobile number, permanent account number (PAN), and shipping/current residence address. We may also collect additional information from you in order to enable us to verify your identity. We may collect your financial information to determine your qualification for the credit line. We may disclose this information for the purposes of credit reporting as required by the Reserve Bank of India and to the Reserve Bank of India regulated lender (Bhanix Finance & Investment Ltd, CASHe) with whom you have signed an agreement for the purchase made on CleverPe.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">In Order To Help Us Further Identify You:</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            We may also gather information about the device you are using to access the services or the site, including any information about the device, its location, IP address of the device, and any other information sent to us by the device. We may also gather information about your use of our site or any merchant website, including your interaction with the credit line application, your past use of the <strong>CLEVERPE TECHNOLOGIES PRIVATE LIMITED</strong> service, any past purchases that you've made using the service, and any actions you have taken relating to your application.
          </p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Information About You From Third-Party Sources:</h2>
          <p className="text-gray-700 text-base leading-relaxed mb-2 text-justify">
            We May Also Obtain Information About You from third-party sources. For example, we work with identity verification companies that will help us identify you. As part of the qualification process, we will obtain information about you, including your personal information and credit report, from credit reporting agencies.
          </p>
          <p className="text-gray-700 text-base leading-relaxed mb-6 text-justify">
            Our App May Further Collect Following Information for credit assessment purposes, to uniquely identify you and prevent frauds and unauthorized access by fraudsters. Any acceptance or denial of such permissions may affect your approval process.
          </p>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default PrivacyPolicy;
