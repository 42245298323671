import React, { useRef, KeyboardEvent, ClipboardEvent, useEffect} from 'react';
import { IoRocketOutline } from "react-icons/io5";
import Lottie from 'lottie-react';
import creditCardLoader from '../../../assets/loader/credit-card-loader.json';

interface OtpStepProps {
  otp: string[];
  handleOtpChange: (value: string, index: number) => void;
  errorMessage: string | null;
  timer: number;
  resendDisabled: boolean;
  handleResendOtp: () => void;
  isLoading: boolean;
}

const OtpStep: React.FC<OtpStepProps> = ({
  otp,
  handleOtpChange,
  errorMessage,
  timer,
  resendDisabled,
  handleResendOtp,
  isLoading
}) => {
  const otpRefs = useRef<(HTMLInputElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  // Listen for clipboard events
  useEffect(() => {
    const handleClipboardRead = async () => {
      try {
        const text = await navigator.clipboard.readText();
        const numbersOnly = text.match(/\d/g);
        if (numbersOnly && numbersOnly.length === otp.length) {
          fillOtpFromClipboard(numbersOnly.join(''));
        }
      } catch (err) {
        console.log('Clipboard access denied');
      }
    };

    // Add click listener to container
    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', handleClipboardRead);
    }

    return () => {
      if (container) {
        container.removeEventListener('click', handleClipboardRead);
      }
    };
  }, [otp.length]);

  const fillOtpFromClipboard = (otpString: string) => {
    const digits = otpString.match(/\d/g);
    if (digits && digits.length === otp.length) {
      digits.forEach((digit, index) => {
        handleOtpChange(digit, index);
      });
      // Focus the last input after filling
      otpRefs.current[otp.length - 1]?.focus();
    }
  };

  // Handle clipboard paste
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    fillOtpFromClipboard(pastedData);
  };

  // Enhanced handleOtpChange for both mobile and desktop
  const handleOtpInputChange = (value: string, index: number) => {
    if (/^\d?$/.test(value)) {
      handleOtpChange(value, index);
      
      // Move to next input if value is entered
      if (value && index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  // Handle keydown events for better navigation
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      e.preventDefault(); // Prevent default backspace behavior
      
      if (otp[index]) {
        // If current field has value, just clear it
        handleOtpChange('', index);
      } else if (index > 0) {
        // If current field is empty, move to previous field
        handleOtpChange('', index); // Clear the previous field
        otpRefs.current[index - 1]?.focus(); // Move focus to previous field
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      e.preventDefault();
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === 'ArrowRight' && index < otp.length - 1) {
      e.preventDefault();
      otpRefs.current[index + 1]?.focus();
    }
  };


  // Common input props
  const getInputProps = (index: number) => ({
    type: "text",
    inputMode: "numeric" as React.HTMLAttributes<HTMLInputElement>["inputMode"], // Ensures numeric keyboard on mobile
    pattern: "[0-9]*",
    maxLength: 1,
    id: `otp-${index}`,
    value: otp[index],
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleOtpInputChange(e.target.value, index),
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index),
    onPaste: handlePaste,
    ref: (el: HTMLInputElement | null) => { otpRefs.current[index] = el },
  });

  return (
    <>
      <div className="w-full h-[400px] md:h-[277px] bg-gradient-lightblue rounded-[20px] text-white p-10 relative">
        {isLoading ? (
          <div className="w-full h-full flex flex-col md:flex-row justify-center items-center">
            <Lottie
              animationData={creditCardLoader}
              className="w-32 h-32 md:w-40 md:h-40" 
            />
            <div className="md:ml-4 text-center md:text-left mt-4 md:mt-0">
              <div className="text-gradient-light text-2xl font-bold">Checking Your Credit Limit</div>
              <div className="text-white text-lg mt-2">Please wait while we process your request...</div>
            </div>
          </div>
        ) : (
          <>
            {/* Desktop View */}
            <div className="hidden md:block">
              <div className="flex space-x-5 justify-center w-full mt-12">
                {otp.map((_, index) => (
                  <input
                    key={index}
                    {...getInputProps(index)}
                    className="w-12 h-12 text-center text-white bg-[#333336] border border-[#444444] rounded-[5px] focus:outline-none focus:ring-2 focus:ring-[#666666] text-[16px] font-gilroy"
                  />
                ))}
              </div>
              {errorMessage && (
                <p className="text-red-500 text-center mt-2">{errorMessage}</p>
              )}
              <button
                type="submit"
                className="mb-2 mt-5 w-full bg-gradient-purple hover:bg-[#512DA8] text-white py-3 rounded-[10px] text-[17px] focus:outline-none focus:ring-2 focus:ring-[#666666] leading-[21px] font-medium flex items-center justify-center space-x-2"
              >
                <span>Check Credit Line</span>
                <IoRocketOutline size={20} className="rotate-45 text-white" />
              </button>
            </div>

            {/* Mobile View */}
            <div className="md:hidden text-center">
              <div className="flex space-x-5 justify-center w-full mt-4">
                {otp.map((_, index) => (
                  <input
                    key={index}
                    {...getInputProps(index)}
                    className="w-10 h-12 text-center text-white bg-[#333336] border border-[#444444] rounded-[5px] focus:outline-none focus:ring-2 focus:ring-[#666666] text-[16px] font-gilroy"
                  />
                ))}
              </div>
              {errorMessage && (
                <p className="text-red-500 text-center mt-2">{errorMessage}</p>
              )}
              <button className="mt-12 w-full bg-gradient-purple hover:bg-[#512DA8] text-white py-3 rounded-[10px] text-[17px] focus:outline-none focus:ring-2 focus:ring-[#666666] leading-[21px] font-medium flex items-center justify-center space-x-2">
                <span>Check Credit Line</span>
                <IoRocketOutline size={20} className="rotate-45 text-white" />
              </button>
              <div className="flex flex-col items-center sm:items-end space-y-2 mt-12 sm:-mt-8 text-center sm:text-right sm:mr-8 w-full">
                <p className="text-[16px] text-white leading-[22px]">
                  We have sent you a code on<br />
                  your registered mobile number
                </p>
                <p className="text-[14px] text-[#AAAAAA] mt-12">
                  Didn't receive a code?{' '}
                  {resendDisabled ? (
                    <span className="text-white cursor-not-allowed opacity-50">
                      Resend in {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}
                    </span>
                  ) : (
                    <span
                      className="underline text-white cursor-pointer"
                      onClick={handleResendOtp}
                    >
                      resend it
                    </span>
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoading && (
        <div className="sm:w-1/2 w-full sm:pl-12 pt-4 flex flex-col justify-center">
          <div className="hidden md:block flex flex-col items-center sm:items-end space-y-2 mt-4 sm:-mt-12 text-center sm:text-right sm:mr-8 w-full">
            <p className="text-[16px] text-white leading-[24px]">
              We have sent you a code on<br />
              your registered mobile number
            </p>
            <p className="text-[14px] text-[#AAAAAA] mt-17">
              Didn't receive a code?{' '}
              {resendDisabled ? (
                <span className="text-white cursor-not-allowed opacity-50">
                  Resend in {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}
                </span>
              ) : (
                <span
                  className="underline text-white cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Resend it
                </span>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default OtpStep;