import React, { useEffect, useRef } from "react";
import hassleFreeVideo from '../assets/videos/hassle-free.mp4';
import instantCardlessVideo from '../assets/videos/instant-cardless-credit.mp4';
import omnichannelVideo from '../assets/videos/omnichannel-presence.mp4';
import paperlessVideo from '../assets/videos/paperless-process.mp4';

const Feature: React.FC = () => {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  useEffect(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.play().catch(error => {
          console.error("Error playing video:", error);
        });
      }
    });
  }, []);

  return (
    <>
      <style>{`
        .box {
          position: relative;
          overflow: hidden;
          animation: fadeInUp 0.6s ease-out;
          animation-fill-mode: both;
          background: rgba(42, 42, 42, 0.9);
          transform-origin: center;
          will-change: transform, opacity;
          transition: transform 0.3s ease-in-out;
        }

        .box:hover {
          transform: translateY(-5px);
        }

        .box:hover video {
          opacity: 0.6;
          transform: scale(1.05);
        }

        .box video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 0;
          opacity: 0.4;
          pointer-events: none;
          transition: all 0.3s ease-in-out;
          transform-origin: center;
        }

        .box-content {
          position: relative;
          z-index: 1;
          width: 100%;
          opacity: 0;
          animation: fadeIn 0.6s ease-out forwards;
          animation-delay: 0.3s;
        }

        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(30px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        /* Media Queries for Mobile Only */
        @media (max-width: 768px) {
          .feature-grid {
            display: flex !important;
            flex-direction: column !important;
            gap: 1rem !important;
            flex-wrap: wrap !important;
          }

          .box {
            width: 100% !important; /* Full width */
            height: auto !important; /* Allow dynamic height based on content */
            margin: 0 auto !important;
          }

          .box:nth-child(1) {
            height: 281px !important; /* Custom height for mobile */
          }

          .box:nth-child(2) {
            height: 513px !important; /* Custom height for mobile */
          }

          .box:nth-child(3) {
            height: 309px !important; /* Custom height for mobile */
          }

          .box:nth-child(4) {
            height: 470px !important; /* Custom height for mobile */
          }
        }
      `}</style>
      <section className="relative z-10 mb-16 text-center">
        <div className="container px-5 mx-auto">
          <h2 className="text-[44px] font-bold tracking-normal text-gradient-light mb-4">
            Core Features
          </h2>
          <p className="mt-4 text-[20px] leading-[24px] font-medium, max-w-[900px] mx-auto text-gradient-light">
            Smart, seamless, and designed for your convenience. Explore the key features that make everything effortless.
          </p>
        </div>
      </section>

      <section className="relative z-10 flex justify-center px-6 py-16 section-container">
        <div className="grid gap-6 mx-auto" style={{ maxWidth: '1200px' }}>
          <div className="grid grid-cols-3 gap-6 feature-grid">
            {/* Box 1: Hassle-Free */}
            <div className="box flex flex-col justify-center p-8 text-left bg-[#2A2A2A] border border-[#3A3A3A]"
                 style={{ width: '379px', height: '342px' }}>
              <video ref={(el) => { videoRefs.current[0] = el || null; }} autoPlay loop muted playsInline className="video-element">
                <source src={hassleFreeVideo} type="video/mp4" />
              </video>
              <div className="box-content">
                <h3 className="text-[46px] leading-tight font-black text-white mb-2">
                  Hassle-Free
                </h3>
                <p className="text-[14px] text-gray-400 font-light">
                  No income proof, No KYC, No Demographic Details.
                </p>
              </div>
            </div>

            {/* Box 2: Instant Cardless Credit */}
            <div className="box flex flex-col justify-center p-8 text-right col-span-2 bg-[#2A2A2A] border border-[#3A3A3A]"
                 style={{ width: '724px', height: '536px' }}>
              <video ref={(el) => { videoRefs.current[1] = el || null; }} autoPlay loop muted playsInline className="video-element">
                <source src={instantCardlessVideo} type="video/mp4" />
              </video>
              <div className="box-content">
                <h3 className="text-[46px] leading-tight font-black text-white mb-2">
                  Instant Cardless<br />Credit
                </h3>
                <p className="text-[14px] text-gray-400 font-light">
                  Get approved within 2 minutes using just your name and mobile number.
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-6 feature-grid">
            {/* Box 3: Omnichannel Presence */}
            <div className="box flex flex-col justify-center p-8 text-right bg-[#2A2A2A] border border-[#3A3A3A]"
                 style={{ width: '379px', height: '561px', marginTop: '-200px' }}>
              <video ref={(el) => { videoRefs.current[2] = el || null; }} autoPlay loop muted playsInline className="video-element">
                <source src={omnichannelVideo} type="video/mp4" />
              </video>
              <div className="box-content">
                <h3 className="text-[46px] leading-tight font-black text-white mb-2">
                  Omnichannel<br />Presence
                </h3>
                <p className="text-[14px] text-gray-400 font-light">
                  Shop online & at offline partner merchants.
                </p>
              </div>
            </div>

            {/* Box 4: Paperless Process */}
            <div className="box flex flex-col justify-center p-8 text-right col-span-2 bg-[#2A2A2A] border border-[#3A3A3A]"
                 style={{ width: '724px', height: '363px' }}>
              <video ref={(el) => { videoRefs.current[3] = el || null; }} autoPlay loop muted playsInline className="video-element">
                <source src={paperlessVideo} type="video/mp4" />
              </video>
              <div className="box-content">
                <h3 className="text-[46px] leading-tight font-black text-white mb-2">
                  Paperless Process
                </h3>
                <p className="text-[14px] text-gray-400 font-light">
                  Completely digital for ultimate convenience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
