import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";
import { Mail, Phone, Globe, Building, Clock, FileText } from "lucide-react";

const ComplaintsPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-6xl font-bold text-gray-900 mb-6">
              Complaints and Grievance Redressal
            </h1>
            <p className="text-xl text-gray-700">
              At CleverPe, we value customer feedback and are committed to resolving any concerns promptly. If you have any complaints or issues with our services, we aim to address them efficiently and transparently.
            </p>
          </div>

          {/* Filing Methods */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              How to File a Complaint
            </h2>
            <div className="grid md:grid-cols-2 gap-6"> {/* Set grid to 2 columns */}
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <FileText className="text-purple-600 mb-4" size={24} />
                <h3 className="text-xl font-semibold mb-3">Online Complaint Form</h3>
                <p className="text-gray-700">
                  Visit{" "}
                  <a
                    href="/contactus"
                    className="text-purple-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us Page or Click Here
                  </a>{" "}
                  and fill out the complaint form with all necessary details.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <Mail className="text-purple-600 mb-4" size={24} />
                <h3 className="text-xl font-semibold mb-3">Email Us</h3>
                <p className="text-gray-700">
                  Send an email to{" "}
                  <a href="mailto:complaints@cleverpe.com" className="text-purple-600">
                    support@cleverpe.com
                  </a>{" "}
                  with your details and complaint description.
                </p>
              </div>
            </div>
          </div>


          {/* Required Information */}
          <div className="mb-12 bg-gradient-to-r from-purple-50 to-white p-8 rounded-xl">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              What Information to Include
            </h2>
            <ul className="space-y-4">
              {[
                "Full Name (as registered on CleverPe)",
                "Registered Mobile Number",
                "Description of the Issue (Include all relevant details)",
                "Transaction Reference Number (if applicable)",
                "Any supporting documents or screenshots related to your complaint"
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <span className="w-2 h-2 mt-2 bg-purple-600 rounded-full mr-3"></span>
                  <span className="text-gray-700">{item}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Resolution Timeline */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Complaint Resolution Timeline
            </h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  title: "Acknowledgment",
                  desc: "You will receive an acknowledgment within 24 hours of lodging your complaint.",
                  icon: Clock
                },
                {
                  title: "Investigation",
                  desc: "Our team will investigate the issue and provide a resolution.",
                  icon: FileText
                },
                {
                  title: "Resolution Time",
                  desc: "Most complaints are resolved within 7 working days. We'll keep you informed if additional time is needed.",
                  icon: Clock
                }
              ].map((item, index) => (
                <div key={index} className="bg-purple-50 p-6 rounded-lg">
                  <item.icon className="text-purple-600 mb-4" size={24} />
                  <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Escalation Process */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Escalation Process
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-3 text-purple-600">Grievance Officer</h3>
                <p className="text-gray-700">
                  Contact our Grievance Officer via email at{" "}
                  <a href="mailto:gro@cleverpe.com" className="text-purple-600">
                    gro@cleverpe.com
                  </a>{" "}
                  with your complaint reference number.
                </p>
              </div>
              {/* <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-3 text-purple-600">Level 2 – Nodal Officer</h3>
                <p className="text-gray-700">
                  If unresolved, escalate to{" "}
                  <a href="mailto:nodalofficer@cleverpe.com" className="text-purple-600">
                    nodalofficer@cleverpe.com
                  </a>. We aim to resolve escalated complaints within 10 working days.
                </p>
              </div> */}
            </div>
          </div>

          {/* FAQs */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              FAQs
            </h2>
            <div className="space-y-4">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold mb-2">Will my account or credit limit be impacted by my complaint?</h3>
                <p className="text-gray-700">No, lodging a complaint will not affect your account or credit limit in any way.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold mb-2">How can I track my complaint?</h3>
                <p className="text-gray-700">You can track the status of your complaint by reaching out to customer support with the reference number provided at the time of lodging your complaint.</p>
              </div>
            </div>
          </div>

          {/* Contact Details */}
          <div className="grid md:grid-cols-2 gap-6 mb-12">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Contact Details
              </h2>
              <div className="space-y-4">
                <div className="flex items-center">
                  <Mail className="text-purple-600 mr-3" size={20} />
                  <a href="mailto:support@cleverpe.com" className="text-gray-700">support@cleverpe.com</a>
                </div>
                {/* <div className="flex items-center">
                  <Phone className="text-purple-600 mr-3" size={20} />
                  <a href="tel:+918559896607" className="text-gray-700">+91 85598 96607</a>
                </div> */}
                <div className="flex items-center">
                  <Globe className="text-purple-600 mr-3" size={20} />
                  <a href="https://www.cleverpe.com/contactus" target="_blank" rel="noopener noreferrer" className="text-gray-700">www.cleverpe.com/contactus</a>
                </div>
                <div className="flex items-start">
                  <Building className="text-purple-600 mr-3 mt-1" size={20} />
                  <span className="text-gray-700">
                    WeWork - RMZ Latitude -10th floor, RMZ Latitude <br /> Commercial Building, Bellary Rd, Hebbal, Bengaluru,<br /> Karnataka 560024
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-gradient-to-r from-purple-50 to-white p-6 rounded-xl">
              <p className="text-gray-700">
                Your satisfaction is our priority, and we strive to resolve all concerns to your full satisfaction. Thank you for choosing CleverPe!
              </p>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default ComplaintsPage;