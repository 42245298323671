import React, { useEffect, useRef } from "react";
import EVHero from '@/components/EcoCP/EVHero';
import EVSimple from '@/components/EcoCP/EVSimple';
import EVPartners from '@/components/EcoCP/EVPartners';
// import ExploreMoreButton from "@/components/EcoCP/ExploreMoreButton";
import TopGradient from '/src/assets/background/EVGradient.png';

const Eco: React.FC = () => {
  const ecoRef = useRef<HTMLDivElement>(null);
  // const smokeRef = useRef<HTMLDivElement>(null);
  const animationTriggered = useRef(false);

  const scrollToEco = () => {
    const yOffset = -50;
    const element = ecoRef.current;
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !animationTriggered.current) {
          animationTriggered.current = true;
          
          // First trigger smoke effect (Commented Out)
          // if (smokeRef.current) {
          //   smokeRef.current.classList.add('animate-smoke-effect');
          //   smokeRef.current.style.display = 'block';
          // }
          
          // Then scroll after 2 seconds
          setTimeout(scrollToEco, 1000);
        }
      },
      {
        threshold: 0.005,
        rootMargin: '0px'
      }
    );

    if (ecoRef.current) {
      observer.observe(ecoRef.current);
    }

    return () => {
      if (ecoRef.current) {
        observer.unobserve(ecoRef.current);
      }
    };
  }, []);

  return (
    <div 
      ref={ecoRef}
      id="evfinancing" 
      className="w-full min-h-screen bg-gradient-to-b from-white to-white relative"
    >
      {/* Enhanced smoke effect overlay (Commented Out) */}
      {/* <div 
        ref={smokeRef}
        className="fixed inset-0 w-screen h-screen pointer-events-none opacity-0 z-[9999] hidden"
        style={{
          background: 'radial-gradient(circle at center, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6))',
          backdropFilter: 'blur(12px)',
          mixBlendMode: 'soft-light',
          WebkitMaskImage: 'radial-gradient(circle at center, black 40%, transparent 100%)'
        }}
      /> */}

      {/* Top gradient image */}
      <div className="absolute top-0 left-0 w-full top-[-10vh] lg:top-[-20vh] h-[20vh] overflow-hidden lg:z-40">
        <img src={TopGradient} alt="" className="w-full h-full object-top object-cover" />
      </div>

      <EVHero />
      <div className="-mt-12">
        <EVSimple />
      </div>
      <EVPartners />
      {/* <ExploreMoreButton /> */}

      {/* Bottom Gradient */}
      <div className="absolute bottom-[-10vh] lg:bottom-[-19.82vh] left-0 w-full h-[20vh] overflow-hidden lg:z-40 rotate-180">
        <img src={TopGradient} alt="Bottom Gradient" className="w-full h-full object-top object-cover" />
      </div>
    </div>
  );
};

export default Eco;
