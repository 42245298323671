import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const LendingPartners = () => {
  const partners = [
    {
      name: "Bhanix Finance & Investment Ltd.",
      logo: new URL('@/assets/lendingpartners/bhanix.png', import.meta.url).href,
      details: {
        title: "Grievance Redressal Officer",
        officer: "Pushpinder Kaur",
        tel: "9983107444",
        email: "grievance@bhanix.in"
      }
    },
    // {
    //   name: "Zype",
    //   logo: "src/assets/lendingpartners/zype.png",
    //   details: {
    //     content: "Content To Be Rec"
    //   }
    // }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-xl mx-auto">
          <h1 className="text-6xl font-bold text-center text-gray-900 mb-12">
            Lending Partners
          </h1>

          <div className="space-y-8">
            {partners.map((partner, index) => (
              <div key={partner.name} className="space-y-2">
                {/* Partner Number and Name - Outside Card */}
                <div className="text-lg text-gray-700 pl-2">
                  {/* {index + 1}. */}
                  {partner.name}
                </div>

                {/* Partner Card */}
                <div className="bg-black rounded-2xl overflow-hidden w-full md:w-[600px] p-4 py-6 sm:py-10 md:py-4">
                  <div className="flex items-center h-full gap-4">
                    {/* Logo Section */}
                    <div className="flex items-center justify-center w-24 h-24 sm:w-32 sm:h-32 md:w-auto md:h-auto">
                      <img
                        src={partner.logo}
                        alt={`${partner.name} logo`}
                        className="object-contain w-full h-full"
                      />
                    </div>

                    {/* Vertical Divider */}
                    <div className="w-px self-stretch bg-white/20"></div>

                    {/* Content Section */}
                    <div className="text-white flex-1">
                      <p className="text-sm sm:text-base md:text-lg font-regular opacity-90">
                        {partner.details.title}:
                      </p>
                      <p className="text-sm sm:text-base md:text-lg font-bold opacity-90">
                        {partner.details.officer}
                      </p>
                      <p className="text-sm sm:text-base md:text-lg font-regular opacity-90">
                        Tel: {partner.details.tel}
                      </p>
                      <p className="text-sm sm:text-base md:text-lg font-regular opacity-90">
                        Email: {partner.details.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default LendingPartners;