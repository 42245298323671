import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
// import DeviceGif from "/src/assets/icon/device.gif";
import Footer from "@/components/Footer";

const AboutUs = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          {/* Title*/}
          <div className="text-center mb-16">
            <div className="md:w-1/2 mx-auto">
              <h1 className="text-6xl font-bold text-gray-900 mb-6">
                About Us
              </h1>
              <h2 className="text-2xl font-bold text-purple-600 mb-4">
                Simplifying Payments for Every Indian
              </h2>
              <p className="text-xl text-gray-700">From Products to Services & Bills to Payments, We Provide India a Smart Way to Pay!</p>
            </div>
            {/* <div className="md:w-1/2 flex justify-center mt-8 md:mt-0">
              <img 
                src={DeviceGif} 
                alt="CleverPe Device" 
                className="w-100 h-auto object-contain"
              />
            </div> */}
          </div>

          {/* Main Description */}
          <div className="space-y-8">
            <div className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-xl shadow-sm">
              <h3 className="text-xl font-semibold mb-4 text-gray-900">
                Your New-Age Tech Buddy
              </h3>
              <p className="text-gray-700 leading-relaxed text-justify">
                CleverPe is a new-age tech-buddy of every Indian, simplifying payment methods through instant, card-less & hassle-free affordability solutions like Buy Now Pay Later & No Cost EMIs. Through our omni-channel presence, customers can pay on EMIs/credits for any product or services either at offline partner merchants or on our online platform through www.cleverpe.com.
              </p>
            </div>

            {/* Key Features Grid */}
            <div className="grid md:grid-cols-2 gap-8 mt-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">
                  Exclusive BNPL Marketplace
                </h3>
                <p className="text-gray-700 text-justify">
                  We offer an exclusive "Buy Now Pay Later" marketplace connecting customers with their local merchant partners. Buy anything and everything on interest-free credits, instantly issued on our platform.
                </p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-3 text-gray-900">
                  2-Minute Credit Approval
                </h3>
                <p className="text-gray-700 text-justify">
                  Experience revolution in instant credit approval with just your NAME & MOBILE NUMBER in 2 MINUTES. No income proof, no KYC documents, no demographic details required.
                </p>
              </div>
            </div>

            {/* Highlights Section */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                What Makes Us Different
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="flex flex-col items-center p-4 bg-purple-50 rounded-lg">
                  <span className="text-lg font-semibold mb-2">Instant</span>
                  <p className="text-center text-gray-600">
                    Quick 2-minute credit approval process
                  </p>
                </div>
                <div className="flex flex-col items-center p-4 bg-purple-50 rounded-lg">
                  <span className="text-lg font-semibold mb-2">Card-less</span>
                  <p className="text-center text-gray-600">
                    No credit card required for EMI payments
                  </p>
                </div>
                <div className="flex flex-col items-center p-4 bg-purple-50 rounded-lg">
                  <span className="text-lg font-semibold mb-2">Hassle-free</span>
                  <p className="text-center text-gray-600">
                    Minimal documentation with maximum convenience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default AboutUs;