import React, { useEffect, useRef, useState } from 'react';
import ApiIcon from '../assets/icon/API.svg';
import GrainBg from '../assets/background/Grain.png';

// Define the Link type as a union type
type Link = string | {
    url: string;
    target: string;
};

// Helper function to type check the link
const isLinkObject = (link: Link): link is { url: string; target: string } => {
    return typeof link === 'object' && link !== null && 'url' in link && 'target' in link;
};

// Helper function to get the URL from a Link type
const getLinkUrl = (link: Link): string => {
    return isLinkObject(link) ? link.url : link;
};

// Helper function to get the target from a Link type
const getLinkTarget = (link: Link): string | undefined => {
    return isLinkObject(link) ? link.target : undefined;
};

const FeaturesCard = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!scrollRef.current) return;

    const scrollContainer = scrollRef.current;
    const cardElement = scrollContainer.querySelector('.feature-card') as HTMLDivElement;
    const cardWidth = cardElement?.offsetWidth || 0;

    const autoScroll = () => {
      if (!scrollContainer) return;

      const maxScroll = scrollContainer.scrollWidth - scrollContainer.offsetWidth;
      const nextScrollPosition = scrollContainer.scrollLeft + cardWidth;

      if (scrollContainer.scrollLeft >= maxScroll - 10) {
        scrollContainer.scrollTo({ left: 0, behavior: 'smooth' });
        setActiveIndex(0);
      } else {
        scrollContainer.scrollTo({ left: nextScrollPosition, behavior: 'smooth' });
        setActiveIndex(prev => (prev + 1) % 4);
      }
    };

    const intervalId = setInterval(autoScroll, 3000);

    const handleScroll = () => {
      if (!scrollContainer || !cardWidth) return;
      const scrollPosition = scrollContainer.scrollLeft;
      const newIndex = Math.round(scrollPosition / cardWidth);
      setActiveIndex(newIndex);
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(intervalId);
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Links array
  const links: Link[] = [
    '/bnpl',
    '/nocostemi',
    '#evfinancing',
    { url: "https://merchant.cleverpe.com/", target: "_blank" }
  ];

  return (
    <>
      <style>
        {`
          @media (max-width: 768px) {
            .scrollbar-hide::-webkit-scrollbar {
              display: none;
            }

            .scrollbar-hide {
              -ms-overflow-style: none;
              scrollbar-width: none;
            }

            .card-container {
              padding-left: 20px;
              margin-left: -20px;
              gap: 16px;
            }

            .feature-card {
              min-width: calc(95% - 40px);
              scroll-snap-align: center;
            }
          }

          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }

          .scrollbar-hide {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .pagination-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.3);
            transition: all 0.3s ease;
          }

          .pagination-dot.active {
            background-color: white;
            width: 24px;
            border-radius: 4px;
          }

          .feature-card {
            background: linear-gradient(to right, #763AF5, #572A6D);
            padding: 3px;
            position: relative;
            isolation: isolate;
          }

          .feature-card::after {
            content: '';
            position: absolute;
            inset: 0;
            background: linear-gradient(to right, #763AF5, #572A6D);
            border-radius: inherit;
            z-index: -1;
          }

          .feature-card-inner {
            background: linear-gradient(180deg, #1A1A1A 0%, #4A1D96 100%);
            border-radius: inherit;
            height: 100%;
            width: 100%;
            overflow: hidden;
          }
        `}
      </style>

      <section id="featurescard" className="relative z-10 mb-16 text-center">
        <div className="container px-5 mx-auto">
          <h2 className="text-[44px] font-bold tracking-normal text-gradient-light mb-4 leading-tight sm:leading-snug">
            Revolutionizing Payments For Everyone.
          </h2>
          <p className="mt-4 text-[20px] leading-[24px] font-medium max-w-[900px] mx-auto text-gradient-light">
            Discover tailored solutions—shop now, pay later, enjoy no-cost EMIs, or finance your dream electric vehicle. Elevate your experience today!
          </p>
        </div>
      </section>

      <section className="relative z-10">
        <div className="container px-5 mx-auto">
          <div
            ref={scrollRef}
            className="flex gap-6 overflow-x-auto md:gap-10 md:flex-nowrap scroll-smooth scrollbar-hide card-container"
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {[1, 2, 3, 4].map((index) => (
              <div
                key={index}
                className="feature-card flex-shrink-0 rounded-3xl h-[500px] w-[100%] md:w-[420px]"
              >
                <div className="feature-card-inner">
                  <div className="relative h-full p-8 md:p-12">
                    <div
                      className="absolute inset-0"
                      style={{
                        backgroundImage: `url(${GrainBg})`,
                        backgroundSize: 'cover',
                        opacity: 1,
                        zIndex: 3,
                      }}
                    />

                    <div
                      className="absolute inset-0 opacity-10"
                      style={{
                        backgroundImage: `
                          linear-gradient(to right, #ffffff 1px, transparent 1px),
                          linear-gradient(to bottom, #ffffff 1px, transparent 1px)
                        `,
                        backgroundSize: '40px 40px',
                        backgroundPosition: 'center center',
                      }}
                    />
                    <div className="relative z-10 flex flex-col h-full">
                      <div className="w-8 h-8 mb-6">
                        <img src={ApiIcon} alt="API Icon" className="w-8 h-8" />
                      </div>

                      <h2 className="text-[32px] md:text-[36px] leading-tight font-medium text-white">
                        {index === 1 && 'Buy Now, Pay Later'}
                        {index === 2 && 'No Cost EMIs'}
                        {index === 3 && 'Electric Vehicle Financing'}
                        {index === 4 && 'For Merchants'}
                      </h2>

                      <p className="text-[18px] md:text-[20px] text-gray-300 mt-6">
                        {index === 1 && 'Shop now and pay later with flexible EMI options.'}
                        {index === 2 && 'Split Payments Over Time Without Extra Costs—No Card Needed.'}
                        {index === 3 && 'Get Up To ₹200,000 In Financing For Electric Bikes With A 100% Digital Process.'}
                        {index === 4 && 'Enhance your business with CleverPe solutions—flexible payments that customers love.'}
                      </p>

                      <div className="mt-auto">
                        <a
                          href={getLinkUrl(links[index - 1])}
                          target={getLinkTarget(links[index - 1])}
                          rel={getLinkTarget(links[index - 1]) ? 'noopener noreferrer' : undefined}
                        >
                          <button className="relative flex items-center gap-2 px-8 py-3 overflow-hidden text-sm font-medium text-white transition-all duration-300 border border-white/30 hover:bg-white/10">
                            KNOW MORE
                            <span className="text-lg">→</span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="flex items-center justify-center gap-2 mt-6 md:hidden">
            {[0, 1, 2, 3].map((index) => (
              <div
                key={index}
                className={`pagination-dot ${activeIndex === index ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesCard;