import React from "react";
import CleverPeLogo from "@/assets/logo/CleverPe_Black_H.svg";
import Footer from "@/components/Footer";

const NoCostEMI = () => {
  const handleRedirect = () => {
    window.location.href = "/#credit-limit-section";
  };
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="py-4 sm:py-6 px-4 sm:px-8 bg-white shadow">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center">
          {/* Responsive Logo Size */}
          <img src={CleverPeLogo} alt="CleverPe Logo" className="h-12 sm:h-16" />
          <a
            href="/"
            className="px-4 sm:px-6 py-2 sm:py-3 bg-gradient-purple text-white text-sm sm:text-base font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
            Go Back
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-8 py-12">
        <div className="max-w-screen-lg mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-6xl font-bold text-gray-900 mb-6">
              No Cost EMI
            </h1>
            <h2 className="text-2xl font-bold text-purple-600 mb-4">
              Understanding Equated Monthly Installments (EMIs) with CleverPe
            </h2>
            <p className="text-xl text-gray-700">
              Your complete guide to smart and flexible financing
            </p>
          </div>

          {/* Introduction */}
          <div className="bg-gradient-to-r from-purple-50 to-white p-8 rounded-xl shadow-sm mb-12">
            <p className="text-gray-700 leading-relaxed text-justify">
              CleverPe makes financing easy and hassle-free with our simple, transparent, and flexible EMI options. Here's everything you need to know about how EMIs work and how you can use them to your advantage.
            </p>
          </div>

          {/* What is EMI Section */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              1. What is an EMI?
            </h2>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <p className="text-gray-700">
                An Equated Monthly Installment (EMI) is a fixed amount you pay every month to repay your loan or purchase amount. EMIs are designed to break down large payments into smaller, manageable sums over a specified tenure.
              </p>
            </div>
          </div>

          {/* Benefits Section */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              2. Benefits of EMIs with CleverPe
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  title: "No Cost EMIs",
                  desc: "Enjoy 0% interest rates on select purchases. Pay only the actual price of the product over the chosen tenure."
                },
                {
                  title: "Flexible Tenure Options",
                  desc: "Choose a repayment plan that works for you—spanning from 3 months to 36 months."
                },
                {
                  title: "Cardless Financing",
                  desc: "No credit card? No problem! Use CleverPe to avail of EMIs with minimal documentation."
                },
                {
                  title: "100% Digital Process",
                  desc: "Apply, verify, and start your EMI journey within minutes—all online and paperless."
                }
              ].map((item) => (
                <div key={item.title} className="bg-white p-6 rounded-lg shadow-lg">
                  <h3 className="text-xl font-semibold mb-3 text-gray-900">{item.title}</h3>
                  <p className="text-gray-700">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>

          {/* How to Avail Section */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              3. How to Avail EMIs with CleverPe
            </h2>
            <div className="grid md:grid-cols-4 gap-6">
              {[
                {
                  title: "Check Eligibility",
                  desc: "Enter your Name and Mobile Number on our form for instant approval."
                },
                {
                  title: "Select an Option",
                  desc: "Shop with partner merchants and opt for financing during checkout."
                },
                {
                  title: "Choose a Plan",
                  desc: "Select your preferred EMI tenure and confirm repayment schedule."
                },
                {
                  title: "Enjoy Your Purchase",
                  desc: "Start using your product while paying in easy installments."
                }
              ].map((item, index) => (
                <div key={item.title} className="bg-purple-50 p-6 rounded-lg">
                  <span className="w-10 h-10 bg-purple-600 text-white rounded-full flex items-center justify-center mb-4">
                    {index + 1}
                  </span>
                  <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>

          {/* EMI Calculation Example */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              4. Example EMI Calculation
            </h2>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <div className="grid md:grid-cols-2 gap-6">
                <div className="p-4 bg-purple-50 rounded-lg">
                  <p className="font-semibold mb-2">Purchase Amount: ₹12,000</p>
                  <p className="font-semibold mb-2">Tenure: 6 Months</p>
                  <p className="font-semibold">No Cost EMI: ₹2,000/month</p>
                </div>
                <div className="p-4">
                  <p className="text-gray-700">
                    For regular EMI options, interest rates may vary based on your credit profile and tenure. Use the EMI calculator on our platform to get exact details.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Documents Required */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              5. Documents Required
            </h2>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <ul className="space-y-4">
                <li className="flex items-center text-gray-700">
                  <span className="w-2 h-2 bg-purple-600 rounded-full mr-3"></span>
                  PAN Card details
                </li>
                <li className="flex items-center text-gray-700">
                  <span className="w-2 h-2 bg-purple-600 rounded-full mr-3"></span>
                  Registered mobile number
                </li>
                <li className="flex items-center text-gray-700">
                  <span className="w-2 h-2 bg-purple-600 rounded-full mr-3"></span>
                  No income proof or extensive paperwork needed!
                </li>
              </ul>
            </div>
          </div>

          {/* FAQs Section */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              6. Frequently Asked Questions (FAQs)
            </h2>
            <div className="space-y-4">
              {[
                {
                  q: "Can I prepay my EMIs?",
                  a: "Yes, CleverPe allows early repayment without penalties on select plans."
                },
                {
                  q: "What happens if I miss an EMI?",
                  a: "Missing an EMI may incur late fees or impact your credit limit. Ensure timely payments to maintain your credit score."
                },
                {
                  q: "Can I use EMIs for any purchase?",
                  a: "EMIs are available for eligible transactions made at partner merchants. Check our marketplace for participating stores."
                }
              ].map((item) => (
                <div key={item.q} className="bg-white p-6 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold mb-2 text-gray-900">Q: {item.q}</h3>
                  <p className="text-gray-700">A: {item.a}</p>
                </div>
              ))}
            </div>
          </div>

          {/* CTA Section */}
          <div className="text-center py-8 bg-gradient-to-r from-purple-50 to-white rounded-xl">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              Ready to Get Started?
            </h2>
            <p className="text-gray-700 mb-6">
              Say goodbye to financial stress and hello to affordability. CleverPe makes owning what you love easier than ever!
            </p>
            <button
            onClick={handleRedirect}
            className="inline-block px-8 py-4 bg-gradient-purple text-white font-semibold rounded-lg hover:from-[#A604F2] hover:to-[#763AF5]"
          >
          📥 Check Your Credit Limit
          </button>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer bgColor="bg-[#2F2E2E]" />
    </div>
  );
};

export default NoCostEMI;