import React from 'react';
import { IoRocketOutline } from "react-icons/io5";

interface FormStepProps {
  customerName: string;
  setcustomerName: (value: string) => void;
  mobileNo: string;
  setmobileNo: (value: string) => void;
  isFirstChecked: boolean;
  setIsFirstChecked: (value: boolean) => void;
  isSecondChecked: boolean;
  setIsSecondChecked: (value: boolean) => void;
  errorMessage: string | null;
}

const FormStep: React.FC<FormStepProps> = ({
  customerName,
  setcustomerName,
  mobileNo,
  setmobileNo,
  isFirstChecked,
  setIsFirstChecked,
  isSecondChecked,
  setIsSecondChecked,
  errorMessage
}) => {
  return (
    <div className="w-full flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
      {/* Left column (Desktop) / Full width in Mobile */}
      <div className="w-full md:w-1/2 space-y-4">
        {/* Input Fields */}
        <input
          type="text"
          placeholder="Name as per PAN"
          value={customerName}
          onChange={(e) => setcustomerName(e.target.value)}
          className="w-full h-12 px-4 bg-[#1E1E1E] border border-[#333333] rounded-lg text-white placeholder-[#666666] focus:outline-none focus:ring-1 focus:ring-[#666666] text-[15px]"
          autoComplete="off"
          spellCheck="false"
        />
        <input
          type="tel"
          placeholder="Phone Number"
          value={mobileNo}
          onChange={(e) => {
            const value = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
            setmobileNo(value);
          }}
          className="w-full h-12 px-4 bg-[#1E1E1E] border border-[#333333] rounded-lg text-white placeholder-[#666666] focus:outline-none focus:ring-1 focus:ring-[#666666] text-[15px]"
          autoComplete="off"
          pattern="\d{10}"
          maxLength={10}
        />

        {/* Checkboxes (Mobile: comes below input fields, Desktop: Right column) */}
        <div className="md:hidden space-y-4">
          <label className="flex items-start gap-3 cursor-pointer group">
            <input
              type="checkbox"
              checked={isFirstChecked}
              onChange={(e) => setIsFirstChecked(e.target.checked)}
              className="mt-1.5 h-4 w-4 rounded border-[#333333] bg-[#1E1E1E] text-[#8B3DFF] focus:ring-[#8B3DFF] focus:ring-offset-0"
            />
            <span className="text-[13px] text-[#CCCCCC] leading-5">
              I consent to CleverPe and its lenders conducting a pre-approval check for my credit eligibility, including 
              sharing my personal and financial data with credit bureaus or other authorized 
              institutions as required by RBI and other regulatory bodies.
            </span>
          </label>
          <label className="flex items-start gap-3 cursor-pointer group">
            <input
              type="checkbox"
              checked={isSecondChecked}
              onChange={(e) => setIsSecondChecked(e.target.checked)}
              className="mt-1.5 h-4 w-4 rounded border-[#333333] bg-[#1E1E1E] text-[#8B3DFF] focus:ring-[#8B3DFF] focus:ring-offset-0"
            />
            <span className="text-[13px] text-[#CCCCCC] leading-5">
              I understand that this information will be used solely for validating my eligibility for 
              financial products/services and to evaluate my credit worthiness.
            </span>
          </label>
        </div>

        {/* Button (Always at the bottom in Mobile) */}
        <button
          type="submit"
          className="w-full h-12 bg-gradient-purple hover:bg-[#512DA8] text-white rounded-lg text-[15px] font-medium flex items-center justify-center transition-colors duration-200"
        >
          <span>Check Credit Limit</span>
          <IoRocketOutline className="rotate-45 ml-2" size={20} />
        </button>
        {errorMessage && (
          <div className="text-red-400 text-sm text-center">{errorMessage}</div>
        )}
      </div>

      {/* Right column - Checkboxes (Only visible in Desktop) */}
      <div className="hidden md:block w-full md:w-1/2 space-y-4">
        <label className="flex items-start gap-3 cursor-pointer group">
          <input
            type="checkbox"
            checked={isFirstChecked}
            onChange={(e) => setIsFirstChecked(e.target.checked)}
            className="mt-1.5 h-4 w-4 rounded border-[#333333] bg-[#1E1E1E] text-[#8B3DFF] focus:ring-[#8B3DFF] focus:ring-offset-0"
          />
          <span className="text-[13px] text-[#CCCCCC] leading-5">
            I consent to CleverPe and its lenders conducting a pre-approval check for my credit eligibility, including 
            sharing my personal and financial data with credit bureaus or other authorized 
            institutions as required by RBI and other regulatory bodies.
          </span>
        </label>
        <label className="flex items-start gap-3 cursor-pointer group">
          <input
            type="checkbox"
            checked={isSecondChecked}
            onChange={(e) => setIsSecondChecked(e.target.checked)}
            className="mt-1.5 h-4 w-4 rounded border-[#333333] bg-[#1E1E1E] text-[#8B3DFF] focus:ring-[#8B3DFF] focus:ring-offset-0"
          />
          <span className="text-[13px] text-[#CCCCCC] leading-5">
            I understand that this information will be used solely for validating my eligibility for 
            financial products/services and to evaluate my credit worthiness.
          </span>
        </label>
      </div>
    </div>
  );
};

export default FormStep;