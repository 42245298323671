import React from 'react';
import packageJson from '../../package.json';

const Version: React.FC = () => {
    return (
        <span className="text-xs text-gray-400 ml-2">
            v{packageJson.version}
        </span>
    );
};

export default Version;