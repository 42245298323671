import React from 'react';
import Background from '@/components/Background';
import Header from '@/components/Header';
import Hero from '@/components/Hero';
import Footer from '@/components/Footer';
import CreditLimitChecker from '@/components/Widgets/CreditLimitChecker/CreditLimitChecker';
import FeaturesCard from '@/components/FeaturesCard';
import Partners from '@/components/Partners';
import Eco from '@/components/Eco';
import Features from '@/components/Features';



export default function HomePage() {

  return (
    <main className="min-h-screen bg-dark relative text-light overflow-x-hidden">
      <Background />
      <Header />
      <Hero />
      
      <div className="py-0 sm:py-10 md:py-16 -mt-16 sm:-mt-14 md:mt-0">  
        <CreditLimitChecker/>
      </div>

      <div className="py-16"> 
        <FeaturesCard />
      </div>

      <div className="py-16"> 
        <Partners />
      </div>

      <div className="py-16"> 
        <Eco />
      </div>

      <div className="py-8" /> 

      <div className="py-16"> 
        <Features />
      </div>

      <div className="mt-20"> 
        <Footer />
      </div>
    </main>
    
  );
}