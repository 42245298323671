import React, { useState } from "react";
import { FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { HiMinus, HiPlus } from "react-icons/hi";
import { FaXTwitter } from "react-icons/fa6";
import CleverPeLogo from "@/assets/logo/CleverPe_White_H.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Version from './Version';

interface Link {
  name: string;
  href: string;
  target?: string; 
}

interface FooterProps {
  bgColor?: string;
}

const Footer: React.FC<FooterProps> = ({ bgColor = "bg-gradient-to-b from-transparent to-gray-900" }) => {
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});

  const toggleSection = (section: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const isExternalLink = (url: string) => {
    // Check if the URL is external by seeing if it contains 'http' or 'www'
    return /^https?:\/\//.test(url) || /^www\./.test(url);
  };


  const currentYear = new Date().getFullYear();

  // const navigate = useNavigate();
  const location = useLocation();

  // const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
  //   if (href.startsWith("#")) {
  //     e.preventDefault(); // Prevent default anchor behavior
  //     const sectionId = href.replace("#", "");

  //     if (location.pathname !== "/") {
  //       navigate("/", { replace: false }); // Navigate to home first
  //       setTimeout(() => scrollToSection(sectionId), 300); // Delay to allow navigation
  //     } else {
  //       scrollToSection(sectionId);
  //     }
  //   }
  // };

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (location.hash) {
      scrollToSection(location.hash.replace("#", ""));
    }
  }, [location]);

  const footerLinks = [
    {
      title: "Quick Links",
      links: [
        { name: "Home", href: "/" },
        { name: "Solutions", href: "/#featurescard" },
        { name: "Marketplace", href: "/marketplace" },
        { name: "EV Financing", href: "/#evfinancing" },
        { name: "Contact", href: "/contactus" },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About CleverPe", href: "/aboutus" },
        { name: "Careers", href: "/careers" },
        { name: "Partner with us", href: "/partnerwithus" },
        { name: "Lending Partners", href: "/lendingpartners" },
        { name: "Privacy Policy", href: "/privacypolicy" },
        { name: "Terms and Conditions", href: "/termsandconditions" },
      ],
    },
    {
      title: "Logins",
      links: [
        { name: "Merchant Login", href: "https://merchant.cleverpe.com/", target: "_blank" },
        { name: "Customer Login", href: "/marketplace" },
      ],
    },
    
    {
      title: "Solutions",
      links: [
        { name: "Check Pre-Approved Credit Limit", href: "/#credit-limit-section" },
        { name: "Explore EV Financing", href: "/#evfinancing" },
        { name: "No Cost EMI", href: "/nocostemi" },
        { name: "For Merchants", href: "https://merchant.cleverpe.com/", target: "_blank" },
      ],
    },
    {
      title: "Support",
      links: [
        { name: "FAQs", href: "/faqs" },
        { name: "EMI Guides", href: "/emiguide" },
        { name: "Complaints", href: "/complaints" },
      ],
    },
    {
      title: "Contact Us",
      links: [
        { name: "Email: support@cleverpe.com", href: "mailto:support@cleverpe.com" },
      ],
    },
  ];

  return (
    <footer className={`relative text-white py-12 ${bgColor}`}>
      {/* Desktop View */}
      <div className="hidden md:grid max-w-screen-xl mx-auto grid-cols-1 md:grid-cols-4 gap-y-12 md:gap-x-8 px-6">
        {/* Column 1: Registered Office and CIN */}
        <div className="flex flex-col items-left text-left">
          <div className="mb-6">
            <img src={CleverPeLogo} alt="Company Logo" className="h-12 w-auto" />
          </div>
          {/* <h2 className="text-xl font-bold mb-2">Registered Office Address</h2> */}
          <p className="text-sm mb-6 font-medium">
            {/* 97, HOSUR, MAINMADIWALA, Bengaluru */}
            <br />
            {/* Bengaluru Urban, Karnataka, 560034 */}
          </p>
          <h2 className="text-xl font-bold mb-2">Corporate Office Address</h2>
          <p className="text-sm mb-6 font-medium">
          10th floor 538 RMZ Latitude Commercial, No. 69/458/69 Bellary Road,
            <br />
            Bengaluru, Karnataka 560024
          </p>
          <h2 className="text-lg font-bold -mt-2">Corporate Identification Number (CIN)</h2>
          <p className="text-sm font-medium">U74999KA2022PTC160186</p>
        </div>

        {/* Column 2: Quick Links and Company */}
        <div className="flex flex-col items-left text-left ml-14">
          <h2 className="text-xl font-bold mb-2">Quick Links</h2>
          <ul className="space-y-1 text-sm mb-6 font-medium">
            {footerLinks[0].links.map((link, idx) => (
              <li key={idx}>
                <a href={link.href} className="hover:underline"  target={isExternalLink(link.href) ? "_blank" : undefined}
                  rel={isExternalLink(link.href) ? "noopener noreferrer" : undefined}>
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
          <h2 className="text-xl font-bold mb-2">Company</h2>
          <ul className="space-y-1 text-sm font-medium">
            {footerLinks[1].links.map((link, idx) => (
              <li key={idx}>
                <a href={link.href} className="hover:underline"  target={isExternalLink(link.href) ? "_blank" : undefined}
                  rel={isExternalLink(link.href) ? "noopener noreferrer" : undefined}>
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Column 3: Logins and Solutions */}
        <div className="flex flex-col items-left text-left">
          <h2 className="text-xl font-bold mb-2">Logins</h2>
          <ul className="space-y-1 text-sm mb-6 font-medium">
            {footerLinks[2].links.map((link, idx) => (
              <li key={idx}>
                <a href={link.href} className="hover:underline"  target={isExternalLink(link.href) ? "_blank" : undefined}
                  rel={isExternalLink(link.href) ? "noopener noreferrer" : undefined}>
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
          <h2 className="text-xl font-bold mb-2">Solutions</h2>
          <ul className="space-y-1 text-sm font-medium">
            {footerLinks[3].links.map((link, idx) => (
              <li key={idx}>
                <a href={link.href} className="hover:underline"  target={isExternalLink(link.href) ? "_blank" : undefined}
                  rel={isExternalLink(link.href) ? "noopener noreferrer" : undefined}>
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Column 4: Support, Contact, and Follow Us */}
        <div className="flex flex-col items-left text-left">
          <h2 className="text-xl font-bold mb-2">Support</h2>
          <ul className="space-y-1 text-sm mb-6 font-medium">
            {footerLinks[4].links.map((link, idx) => (
              <li key={idx}>
                <a href={link.href} className="hover:underline"  target={isExternalLink(link.href) ? "_blank" : undefined}
                  rel={isExternalLink(link.href) ? "noopener noreferrer" : undefined}>
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
          <h2 className="text-xl font-bold mb-2">Contact Us</h2>
          <p className="text-sm mb-6 font-medium">
            <a href="mailto:support@cleverpe.com" className="hover:underline">
              support@cleverpe.com
            </a>
          </p>
          <h2 className="text-xl font-bold mb-2">Follow Us</h2>
          <div className="flex justify-left space-x-4">
            <a href="https://www.linkedin.com/company/cleverpe"  target="_blank" className="hover:text-gray-400">
              <FaLinkedin className="text-2xl" />
            </a>
            <a href="https://x.com/CleverPe33947" target="_blank" className="hover:text-gray-400">
              <FaXTwitter className="text-2xl" />
            </a>
            <a href="https://www.youtube.com/@CleverPe-fx6kv" target="_blank" className="hover:text-gray-400">
              <FaYoutube className="text-2xl" />
            </a>
            <a href="https://www.instagram.com/cleverpe_india/" target="_blank" className="hover:text-gray-400">
              <FaInstagram className="text-2xl" />
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom - Desktop */}
      <div className="text-center mt-12 text-sm text-gray-400 hidden md:block font-medium">
        <div className="max-w-screen-xl mx-auto px-6 relative">
          <div className="flex items-center justify-center">
            <span>©{currentYear} CleverPe Technologies Private Limited - All Rights Reserved.</span>
          </div>
          <div className="absolute right-6 bottom-0">
            <Version />
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="block md:hidden px-6">
        {/* Company Logo */}
        <div className="flex justify-center mb-6">
          <img src={CleverPeLogo} alt="Company Logo" className="h-12 w-auto" />
        </div>

        {/* Footer Sections */}
        {footerLinks.map(({ title, links }, idx) => (
          <div key={idx} className="border-t border-white-600">
            <button
              className="w-full flex justify-between items-center py-4"
              onClick={() => toggleSection(title)}
            >
              <span className="text-sm font-medium">{title}</span>
              {expandedSections[title] ? <HiMinus className="text-lg" /> : <HiPlus className="text-lg" />}
            </button>
            {expandedSections[title] && (
              <ul className="space-y-1 pl-4 text-sm">
                {links.map((link, idx) => (
                  <li key={idx}>
                    <a href={link.href} className="hover:underline">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}

        {/* Address Section */}
       <div className="mt-6 text-center">
          <h2 className="text-lg font-bold">Registered Office Address</h2>
          <div className="text-sm text-center font-medium">
            <p>97, HOSUR, MAINMADIWALA, Bengaluru, Bengaluru Urban, Karnataka, 560034</p>
          </div>
          <h2 className="text-lg font-bold">Corporate Office Address</h2>
          <div className="text-sm text-center font-medium">
            <p>WeWork - RMZ Latitude -10th floor, RMZ Latitude Commercial Building, Bellary Rd, Hebbal, Bengaluru, Karnataka 560024</p>
          </div>
          <h2 className="text-lg font-bold mt-4">Corporate Identification Number (CIN)</h2>
          <p className="text-sm font-medium text-center">U74999KA2022PTC160186</p>
        </div>

        {/* Copyright Mobile */}
        <div className="mt-6 text-xs text-center text-gray-400 md:hidden">
          <div className="flex items-center justify-center mb-1">
            <span>© {currentYear} CleverPe Technologies Private Limited - All Rights Reserved.</span>
          </div>
          <div className="text-right">
            <Version />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;