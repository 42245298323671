import React from 'react';

export default function Hero() {
  return (
    <>
      <section className="relative z-10 flex items-center justify-center min-h-screen text-center -mt-16">
        <div className="max-w-[1200px] mx-auto px-4">
          {/* The Smartest Way */}
          <h1 className="text-[32px] md:text-[44px] font-medium tracking-wide mb-10 md:mb-4 -mt-8 text-gradient-light">
            The Smartest Way
          </h1>

          {/* India Pays */}
          <div className="flex items-center justify-center mb-2 space-x-6 -mt-16">
            <h2
              className="text-[80px] md:text-[154px] font-bold tracking-tight leading-none bg-gradient-to-r from-white via-[#E0E0E0] to-[#E0E0E0] bg-clip-text text-transparent"
            >
              India
            </h2>
            <h2
              className="text-[80px] md:text-[154px] font-bold tracking-tight leading-relaxed  bg-gradient-to-r from-[#D4D4D4] via-[rgb(202,110,245)] to-[#A604F2] bg-clip-text text-transparent"
            >
              Pays
            </h2>
          </div>

          {/* Description */}
          <p className="text-[16px] md:text-[20px] max-w-[900px] mx-auto leading-[1.4] font-light text-gradient-light]">
            Revolutionizing Payments For Every Indian. Enjoy Instant, Cardless, And Hassle-Free Affordability Solutions Like Buy Now Pay Later, No Cost EMIs, And More
          </p>
        </div>
      </section>
    </>
  );
}