import React, { useEffect, useRef } from 'react';

export default function Background() {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const grid = containerRef.current;
    if (grid) {
      const columns = Math.floor(window.innerWidth / 100); // Number of columns based on grid size
      const cells = Array.from(grid.querySelectorAll<HTMLDivElement>('.grid-cell'));

      // Group cells into rows
      const rowsCells = Array(Math.ceil(cells.length / columns))
        .fill(null)
        .map((_, rowIndex) =>
          cells.slice(rowIndex * columns, (rowIndex + 1) * columns)
        );

      // Apply color to 2-3 random cells per row
      rowsCells.forEach((row) => {
        const selectedCells = row
          .sort(() => Math.random() - 0.5) // Shuffle cells in the row
          .slice(0, 1 + Math.floor(Math.random() * 1.5)); // Select 2-3 random cells
        selectedCells.forEach((cell) => {
          cell.style.backgroundColor = '#383838'; // Alternate color
        });
      });
    }
  }, []);

  return (
    <div className="fixed inset-0 overflow-hidden">
      {/* Grid pattern */}
      <div
        ref={containerRef}
        className="absolute inset-0 grid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 100px)', // Grid size of 100px
          gridTemplateRows: 'repeat(auto-fill, 100px)',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(44, 44, 44)',
          zIndex: -1,
        }}
      >
        {Array.from({ length: 1000 }).map((_, index) => (
          <div
            key={index}
            className="grid-cell"
            style={{
              width: '100px',
              height: '100px',
              backgroundColor: 'rgb(44, 44, 44)',
              boxShadow: '0 0 0 0.5px #6f7072', // Optional: Grid borders
            }}
          />
        ))}
      </div>

      {/* Top Glow */}
      <div
        className="absolute"
        style={{
          position: 'absolute',
          width: '1650px',
          height: '1600px',
          left: '-250px',
          top: '-850px',
          background: 'radial-gradient(closest-side, rgba(220, 210, 210, 0.2) 0%, rgba(210, 210, 210, 0.2) 100%)',
          filter: 'blur(65px)',
          zIndex: -1,
        }}
      />

      {/* Black Fade Left (Now a simple fade on the side) */}
      <div
        className="absolute inset-y-0 left-0 w-1/4"
        style={{
          background: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          height: '100vh', // Ensure it covers the full height of the screen
          zIndex: -1,
        }}
      />

      {/* Black Fade Right (Now a simple fade on the side) */}
      <div
        className="absolute inset-y-0 right-0 w-1/4"
        style={{
          background: 'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          height: '100vh', // Ensure it covers the full height of the screen
          zIndex: -1,
        }}
      />

      {/* Black Fade Bottom */}
      <div
        className="absolute bottom-0 left-0 w-full"
        style={{
          background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          height: '500px', // Adjust height as needed
          zIndex: -1,
        }}
      />
    </div>
  );
}